import React, { useEffect, useState } from 'react';
import { Alert, Modal, ModalBody, ModalHeader, Spinner} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Error } from '../../../Components/error';
import { addToGallery } from '../../../helpers/backend_helper';
import {useParams} from 'react-router-dom';


export const AddToGalleryModal = (
    {
        isOpen,
        toggle,
        onSuccess = ()=>{}
    }
) => {

    const [caption,setCaption] = useState('')
    const [file,setFile] = useState(null);


    const [success , setSuccess] = useState(null);
    const [error , setError] = useState(null);
    const [loading , setLoading] = useState(false);
    const [timeout, setTimeoutt] = useState(null);


    function resetNotification(){
        setSuccess(false);
        setError(false);
    }

    function clearTimeoutt(){
      if(timeout){
        clearTimeout(timeout);
      }
    }

    function reset(){
        resetNotification();
        setLoading(false);
        setFile(null);
        setCaption('');
    }

    useEffect(()=>{
        if(error){
          var timer =  setTimeout(resetNotification,7000)
          setTimeoutt(timer)
        }else if(success){
          var timer = setTimeout(()=>{
            reset();
            toggle();
          },5000)
          setTimeoutt(timer)
        }
    },[error,success])

    let {id} = useParams();

    function checkOrError(val, errs, err) {
        if (!val) {
          errs.push(err);
        }
        
    }
  
    function submitData(){
        var formdata = new FormData();
        formdata.append("name",caption)
        formdata.append("image",file)
        formdata.append("propertyy",id)

        addToGallery(formdata)
        .then((res) =>{
          setSuccess("Image added to gallery successfully")
          onSuccess()
        })
        .catch((res) => setError(res.message))
        .finally(() => setLoading(false))
    }

    function submit(){
        setLoading(true)
        var errs = []
        checkOrError(caption,errs,'you need to add a caption')
        checkOrError(file,errs,'you need to select an image')

        if(errs.length > 0){
            setError(errs)
            setLoading(false)
        }else{
            submitData()
        }
    }

    

    return (
    <Modal
      id="galleryModals"
      tabIndex="-1"
      isOpen={isOpen}
      toggle={() => {
        reset();
        clearTimeoutt();
        toggle();
      }}
      centered
      className="modal-dialog-scrollable"
    >
      <ModalHeader
        className="p-3"
        toggle={() => {
          reset();
          clearTimeoutt();
          toggle();
        }}
      >
        Add To Gallery
      </ModalHeader>


    {success && success ? (     
            <Alert className="rounded-0 mb-0" color="success">
                {success}
            </Alert>
        ) : null}

    {error && error ? <Error className='rounded-0 mb-0' error={error} /> : null}


      <ModalBody>
        <form>
          <div className="mb-3">
            <label htmlFor="imgCaption" className="form-label">
              Image Caption
            </label>
            <input
              type="text"
              className="form-control"
              id="imgCaption"
              placeholder="Enter Image Caption"
              onChange={(e)=> setCaption(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="imgInput" className="form-label">
              Your Image
            </label>
            <input
              type="file"
              className="form-control"
              id="imgInput"
              placeholder="Select Image"
              accept="image/*"
              onChange={(e)=> setFile(e.target.files[0])}
            />
          </div>
          <div className="text-end">
            <button onClick={submit} type="button" 
            disabled={
                loading || success || error ? true : false
            }
            className="btn btn-primary">

            {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
            ) : null}
            Add To Gallery
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
