//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postLogin, getUserInfo
} from "../../../helpers/backend_helper";

import { loginSuccess, setLoading ,getInfoSuccess , logoutUserSuccess, apiError, reset_login_flag } from './reducer';

import { api } from "../../../config";
import { resetAuthorization } from "../../../helpers/api_helper";

import { loadingActions } from "../../loading/reducer";
import { deletePrivateFileCache } from "../../../Components/utils/get_file";

export const loginUser = (username,password,keepMeLogin,onSuccess) => async (dispatch) => {
  
      dispatch(setLoading())
      await postLogin(username,password)
        .then(async (res) =>{
            dispatch(loginSuccess({...res,keepMeLogin:keepMeLogin}))
            if(onSuccess)
              onSuccess()
        })
        .catch((err)=>{
          // console.log(err)
          if (err.status === 400){
            dispatch(apiError({data:'Invalid credentials'+', Please try again'}))
          }else{
            dispatch(apiError({data:err.autoMessage+' , Please try again'}))
          }
        })
};


export const getInfo = (token) => async (dispatch) => {

  dispatch(loadingActions.setLoading(true))
  // console.log("token",token)
  await getUserInfo(token).then(async (res) =>{
      dispatch(getInfoSuccess({...res,token:token,keepMeLogin:true}))
    })
    .catch((err)=>{
      deletePrivateFileCache()
      dispatch(logoutUserSuccess())
    })
    .finally(()=> dispatch(loadingActions.setLoading(false)))
}


export const logoutUser = (Token) => async (dispatch) => {
  
  dispatch(setLoading())

  await fetch(api.API_URL+'/user/logout/',
  {
      method:'POST',
      headers:{
          'Content-Type':'application/json',
          'Authorization':'Token ' +Token
      }
  }
  )
  .finally(()=>{
    dispatch(logoutUserSuccess())
    deletePrivateFileCache()
    // resetAuthorization()
  })
  
  
};

export const socialLogin = (type, history) => async (dispatch) => {
  // try {
  //   let response;

  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const fireBaseBackend = getFirebaseBackend();
  //     response = fireBaseBackend.socialLoginUser(type);
  //   }
  //   //  else {
  //     //   response = postSocialLogin(data);
  //     // }
      
  //     const socialdata = await response;
  //   if (socialdata) {
  //     sessionStorage.setItem("authUser", JSON.stringify(response));
  //     dispatch(loginSuccess(response));
  //     history('/dashboard')
  //   }

  // } catch (error) {
  //   dispatch(apiError(error));
  // }
};

export const resetLoginFlag = () => async (dispatch) =>{
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};