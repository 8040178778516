import { useMemo, useState } from "react";
import { Progress } from "reactstrap";
import { toastError } from "../../error";

export const useProgressBarWithLabelAndErrorNotifier = () => {
  const [progressBarState, setProgressBarState] = useState({
    error: null,
    finished: null,
    progress: 0,
  });

  const className = useMemo(
    () =>
      progressBarState.error
        ? "danger"
        : progressBarState.finished
        ? "success"
        : "secondary",
    [progressBarState]
  );

  // console.log(className,progressBarState)
  const changeState = (key, val) => {
    setProgressBarState((oldparams) => {
      const params = { ...oldparams };
      params[key] = val;
      if ((key === "finished" && val === true) || key === "error") {
        params["progress"] = 100;
      }
      return params;
    });
  };

  const setProgress = (val) => changeState("progress", val);
  const onSuccess = () => changeState("finished", true);
  const onFailure = (err) => changeState("error", err);
  const resetState = () =>
    setProgressBarState({
      error: null,
      finished: null,
      progress: 0,
    });

  return [
    <div className="flex-grow-1">
      <div>
        <Progress
          value={progressBarState.progress}
          color={className}
          className="animated-progess custom-progress progress-label mt-2"
        >
          <div
            onClick={() => {
              if (progressBarState.error) {
                toastError(progressBarState.error);
              }
            }}
            className="label"
            style={progressBarState.error ? { cursor: "pointer" } : null}
          >
            {progressBarState.error ? (
              <i
                style={{ color: "red" }}
                className="ri-alert-fill fs-16 align-middle"
              ></i>
            ) : (
              progressBarState.progress + "%"
            )}
          </div>
        </Progress>
      </div>
    </div>,
    setProgress,
    onSuccess,
    onFailure,
    resetState
  ];
};
