import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Col,
  Spinner,
  Input,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import { Error } from "../../../../Components/error";

import { CreateForm } from "../upload/createForm";

const UpdateModal = ({
  edited,
  toggle,
  onSuccess = () => {},
  success,
  error,
}) => {

  return (
    <Modal
      id="UpdateModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      size="xl"
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Contractor Data Edit
      </ModalHeader>

      {success ? <Alert color="success">{success}</Alert> : null}

      {error ? <Error error={error} /> : null}

      <ModalBody>
        <CreateForm selected={edited} onSuccess={onSuccess}/>
      </ModalBody>
    </Modal>
  );
};

export default UpdateModal;
