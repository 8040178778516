import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";

import classnames from "classnames";

import { CustomSelect } from "../../../Components/utils/select";

// import { useAddress } from "./address";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

import LocationPicker from "react-location-picker";

import Dropzone from "react-dropzone";

import { Error } from "../../../Components/error";

// import LocationModal from "./LocationModal";

// backend
import {
  getAllPropertyUse,
  getAllPropertyType,
} from "../../../helpers/backend_helper";

import { useSelector, useDispatch } from "react-redux";

import {
  resetMessages,
  createProductFailure,
  setIsLoading,
  resetState,
} from "../../../slices/property/createProperty/reducer";
import {
  PropertiesDropdownThunk,
  createPropertyThunk,
} from "../../../slices/thunks";
import LocationModal from "./LocationModal";
import cities from "../../../assets/data/cities.json";

export const LocationEditModal = ({
  show,
  toggle,
  city,
  country,
  onSaveClick,
  initialPosition
}) => {
  const [currentPosition, setCurrentPosition] = useState("");

  const [pickedPosition, setpickedPosition] = useState("");

  const [showLocation, setShowLocation] = useState(false);

  function onCityClick() {
    var cityObj;
    const arr = city.split(", ");
    if (arr.length === 1) {
      cityObj = cities[country].find((ele) => ele.cityName === city);
    } else {
      cityObj = cities[country][arr[1]].find((ele) => ele.cityName === arr[0]);
    }
    const position = cityObj.coordinates;
    const obj = {
      lat: parseFloat(position.lat),
      lng: parseFloat(position.lon),
    };
    setCurrentPosition(obj);
    setpickedPosition(obj);
    setShowLocation(false);
  }

  function onCurrentLocationClick() {
    handleLocationClick();
    setShowLocation(false);
  }

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successfunc, errorfunc);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function successfunc(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setCurrentPosition({ lat, lng });
    setpickedPosition({ lat, lng });
  }

  function errorfunc() {
    console.log("Unable to retrieve your location");
  }

  function getUrl() {
    return "https://maps.google.com/?q=" +
    (pickedPosition
      ? pickedPosition.lat + "," + pickedPosition.lng
      : "")
  }

  useEffect(()=>{
    if(initialPosition){
      setpickedPosition(initialPosition)
    }
  },[initialPosition,show])

  return (
    <Modal size="lg" className="modal-dialog-scrollable" isOpen={show} toggle={toggle} centered={true}>
      <LocationModal
        onCityClick={onCityClick}
        onCurrentLocationClick={onCurrentLocationClick}
        onCloseClick={() => setShowLocation(false)}
        show={showLocation}
      />
      <ModalHeader>
        <h5 className="Modal-title mb-0">Property Location</h5>
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <Label className="form-label" htmlFor="location-link">
            Location Link
          </Label>
          <Row>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="location-link"
                name="locationLink"
                placeholder="Property Location Link"
                readOnly
                disabled
                value={
                  "https://maps.google.com/?q=" +
                  (pickedPosition
                    ? pickedPosition.lat + "," + pickedPosition.lng
                    : "")
                }
              />
              <button
                className="btn btn-outline-success"
                type="button"
                id="button-addon2"
                onClick={() => setShowLocation(true)}
                // onClick={handleLocationClick}
                // disabled={currentPosition ? true : false}
              >
                <i style={{ fontSize: "20px" }} className="bx bx-bx bx-map"></i>
              </button>
              <button
                class="btn btn-outline-success"
                type="button"
                // id="button-addon2"
                onClick={() =>
                  onSaveClick(
                    getUrl()
                  )
                }
              >
                <i style={{ fontSize: "20px" }} className="mdi mdi-map-search-outline"></i>
              </button>
            </div>
          </Row>
        </div>
        {currentPosition || pickedPosition ? (
          <LocationPicker
            containerElement={
              <div style={{ width: "100%", height: "480px" }} />
            }
            mapElement={<div style={{ height: "480px" }} />}
            defaultPosition={pickedPosition ? pickedPosition : currentPosition}
            onChange={({ position, address, places }) =>
              setpickedPosition({
                lat: position.lat,
                lng: position.lng,
              })
            }
            raduis={-1}
          />
        ) : (
          <div
            style={{
              textAlign: "center",
              width: '"100%',
              height: "480px",
              backgroundColor: "black",
            }}
          >
            <p
              style={{
                paddingTop: "50px",
                padding: "20px",
                fontWeight: 400,
                fontSize: "20px",
                textAlign: "center",
                color: "white",
              }}
            >
              press the location icon to start picking up your property location
              , you need to allow get current location permessions
            </p>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
