import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Login Method
export const postLogin = (username,password) => api.create(url.LOGIN, {email:username,password:password});

export const getUserInfo = (token) => api.get(url.GETUSERINFO,{},
    {'Content-Type':'application/json',"Authorization": `Token ${token}`})

export const getRstPWD = (uid,token) => api.get(url.RESETPWD(uid,token))

export const postRstPWD = (uid,token,data) => api.create(url.RESETPWD(uid,token),data)

export const getActUser = (uid,token) => api.get(url.GETACTIVATEUSER(uid,token))

export const getRoles = () => api.get(url.GETROLES)

export const checkEmail = (email) => api.create(url.CHECKEMAIL,{"email":email})

export const register = (data) => api.create(url.REGISTER,data)

export const changePwd = (data) => api.create(url.CHANGEPWD,data) 


// profile

export const getProfile = (uid) => api.get(url.GETPROFILE(uid))

export const updateProfile = (uid,data) => api.put(url.UPDATEPROFILE(uid),data)

// contractor profile

export const getContractorProfile = (uid) => api.get(url.CONTRACTORGETPROFILE(uid))

export const updateContractorProfile = (uid,data) => api.put(url.CONTRACTORUPDATEPROFILE(uid),data)


export function getProfileMethod(user) {
    if (user.role === "property owner") {
        return getProfile
    }else if(user.role === "contractor") {
        return getContractorProfile
    }
    return getProfile
}

export function UpdateProfileMethod(user) {
    if (user.role === "property owner") {
        return updateProfile
    }else if(user.role === "contractor") {
        return updateContractorProfile
    }
    return updateProfile
}


// property

export const getAllPropertyUse = () => api.get(url.GETALLPROPERTYUSE)

export const getAllPropertyType = (searchparams) => api.get(url.GETALLPROPERTYTYPE,searchparams)

export const createProperty = (data) => api.create(url.CREATEPROPERTY,data)

export const getAllProperties = (queryparams) => api.get(url.GETPROPERTIES,queryparams)

export const getProperty = (id) => api.get(url.GETPROPERTY(id))

export const EditProperty = (id,data) => api.put(url.EDITPROPERTY(id),data)

export const addToGallery = (data) => api.create(url.ADDTOGALLERY,data)

export const getGallery = (pid) => api.get(url.GETGALLERY(pid))

export const deleteGallery = (id) => api.delete(url.DELETEGALLERY(id))

export const propertiesDropdownList = () => api.get(url.GETDROPDOWNPROPERTIES)

export const propertyCaptures = (pid,params) => api.get(url.PROPERTYCAPTURES(pid),params)

export const getCaptureDataSrcs = () => api.get(url.CAPTUREDATASRC)

export const createCapture = (pid , data) => api.create(url.PROPERTYCAPTURES(pid),data)

export const retrieveCapture = (cid) => api.get(url.CAPTUREUPDATE(cid))

export const updateCapture = (cid , newData) => api.put(url.CAPTUREUPDATE(cid),newData)

export const deleteCapture = (cid) => api.delete(url.CAPTUREUPDATE(cid))

export const retrieveCaptureSpaces = (cid) => api.get(url.CAPTURESpaces(cid))

export const getSpaceSelectSingleList = (pid) => api.get(url.SPACESELECTLIST(pid))

export const getFloorsSideNav = (pid) => api.get(url.SIDENAVLEVELS(pid))

export const getSpaceSelectList = getFloorsSideNav

export const GetStatuses = () => api.get(url.STATUSELEMENT)

export const getfloors = (pid,params) => api.get(url.GETALLFLOORS(pid),params)

export const getFloorTypes = () => api.get(url.GETALLFLOORTYPES)

export const getFloorFileTypes = () => api.get(url.GETALLFLOORFILETYPES)

export const createFloor = (pid,data) => api.create(url.CREATEFLOOR(pid),data)

export const deleteFloor = (fid) => api.delete(url.DELETEFLOOR(fid))

export const updateFloor = (fid,data) => api.put(url.UPDATEFLOOR(fid),data)

export const scanSpacesFromImage = (data) => api.create(url.SCANSPACESFROMIMAGE,data)

export const scanSpacesFromID = (id) => api.create(url.SCANSPACESFROMID(id))

export const getAllSpaces = (fid,params) => api.get(url.GETALLSPACES(fid),params)

export const createSpace = (fid,data) => api.create(url.CREATESPACE(fid),data)

export const deleteSpace = (sid) => api.delete(url.DELETESPACE(sid))

export const updateSpace = (sid,data) => api.put(url.UPDATESPACE(sid),data)

export const getSpaceTypes = () => api.get(url.GETSPACETYPES)

export const getAllImagesProperty = (pid,parms) => api.get(url.PROPERTYIMAGES(pid),parms)

export const addImage = (pid,data) => api.create(url.PROPERTYIMAGES(pid),data)

export const deleteImage = (Iid) => api.delete(url.PROPERTYIMAGESUPDATE(Iid))

export const updateImage = (Iid,data) => api.put(url.PROPERTYIMAGESUPDATE(Iid),data)

//inventory
export const getInventory = (pid,parms) => api.get(url.GETINVENTORY(pid),parms)

export const InventorySpaces = (pk) => api.get(url.INVENTORYSpaces(pk))

export const createInventory = (pid,data) => api.create(url.GETINVENTORY(pid),data)

export const deleteInventory = (id) => api.delete(url.UPDATEINVENTORY(id))

export const updateInventory = (id,data) => api.put(url.UPDATEINVENTORY(id),data)

export const getInventoryTypes = () => api.get(url.INVENTORYTYPES)

export const retrieveInventory = (pk) => api.get(url.UPDATEINVENTORY(pk))

// utility
export const getutility = (pid,parms) => api.get(url.GETBILLS(pid),parms)

export const createutility = (pid,data) => api.create(url.GETBILLS(pid),data)

export const deleteutility = (id,type) => api.delete(url.UPDATEBILL(id)+'?type='+type)

export const updateutility = (id,data,type) => api.put(url.UPDATEBILL(id)+'?type='+type,data)

export const getutilityTypes = () => api.get(url.GETBILLTYPES)

export const utilitySpaces = (pk,params) => api.get(url.BILLSpaces(pk),params)

export const getutilitySpaces = (pk,type) => api.get(url.BILLSpaces(pk)+'?type='+type)

export const retrieveUtility = (pk) => api.get(url.UPDATEBILL(pk))
// appliance
export const getAppliance = (pid,parms) => api.get(url.GETAPPLIANCE(pid),parms)

export const createAppliance = (pid,data) => api.create(url.GETAPPLIANCE(pid),data)

export const deleteAppliance = (id,type) => api.delete(url.UPDATEAPPLIANCE(id))

export const updateAppliance = (id,data) => api.put(url.UPDATEAPPLIANCE(id),data)

export const retrieveAppliance = (pk) => api.get(url.UPDATEAPPLIANCE(pk))

export const getApplianceTypes = () => api.get(url.APPLIANCETYPES)

export const getApplianceEnergyTypes = () => api.get(url.APPLIANCEENERGYTYPES)

export const getApplianceDetail = (pk) => api.get(url.APPLIANCEDETAIL(pk))

export const ApplianceaddImage = (hid,data) => api.create(url.ApplianceADDIMAGE(hid),data)

export const AppliancedeleteImage = (pk) => api.delete(url.ApplianceREMOVEIMAGE(pk))

export const ApplianceaddReciept = (hid,data) => api.create(url.ApplianceADDRECIEPT(hid),data)

export const AppliancedeleteReciept = (pk) => api.delete(url.ApplianceREMOVERECIEPT(pk))

// HVAC 

export const getHVAC = (pid,parms) => api.get(url.GETHVAC(pid),parms)

export const createHVAC = (pid,data) => api.create(url.GETHVAC(pid),data)

export const deleteHVAC = (id) => api.delete(url.UPDATEHVAC(id))

export const updateHVAC = (id,data) => api.put(url.UPDATEHVAC(id),data)

export const retrieveHVAC = (pk) => api.get(url.UPDATEHVAC(pk))

export const editRetrieveHVAC = (pk) => api.get(url.EDITRETRIEVE(pk))

export const getHVACTypes = () => api.get(url.HVACTYPES)

export const getHVACEnergyTypes = () => api.get(url.HVACENERGYTYPES)

export const HVACaddImage = (hid,data) => api.create(url.HVACADDIMAGE(hid),data)

export const HVACdeleteImage = (pk) => api.delete(url.HVACREMOVEIMAGE(pk))

export const HVACaddReciept = (hid,data) => api.create(url.HVACADDRECIEPT(hid),data)

export const HVACdeleteReciept = (pk) => api.delete(url.HVACREMOVERECIEPT(pk))

export const retrieveHVACSpaces = (pk) => api.get(url.HVACSpaces(pk))



// Location

export const GetLocation = (pid) => api.get(url.LOCATIONURL(pid)) 

export const updateLocationData = (pid,data) => api.put(url.LOCATIONURL(pid),data)

// MY Contractors
export const getMyContractors = (pid,parms) => api.get(url.MYCONTRACTORSLIST(pid),parms)

export const createMyContractors = (pid,data) => api.create(url.MYCONTRACTORSLIST(pid),data)

export const deleteMyContractors = (id) => api.delete(url.MYCONTRACTORSEDIT(id))

export const updateMyContractors = (id,data) => api.put(url.MYCONTRACTORSEDIT(id),data)

export const searchContractors = (data) => api.create(url.CONTRACTORSEARCH,data)

export const getcontractorSelect = (pid) => api.get(url.MYCONTRACTORSELECT(pid))


// FLOORING 
export const getFLOORING = (pid,parms) => api.get(url.GETFLOORING(pid),parms)

export const createFLOORING = (pid,data) => api.create(url.GETFLOORING(pid),data)

export const deleteFLOORING = (id) => api.delete(url.UPDATEFLOORING(id))

export const updateFLOORING = (id,data) => api.put(url.UPDATEFLOORING(id),data)

export const retrieveFLOORING = (pk) => api.get(url.UPDATEFLOORING(pk))

export const editRetrieveFLOORING = (pk) => api.get(url.EDITRETRIEVEFLOORING(pk))

export const getFLOORINGTypes = () => api.get(url.FLOORINGTYPES)

export const getFLOORINGPlacementTypes = () => api.get(url.FLOORINGPLACEMENTTYPES)

export const getInstallationTypes = () => api.get(url.INSTALLATIONtYPES)

export const FLOORINGaddImage = (hid,data) => api.create(url.FLOORINGADDIMAGE(hid),data)

export const FLOORINGdeleteImage = (pk) => api.delete(url.FLOORINGREMOVEIMAGE(pk))

export const FLOORINGaddReciept = (hid,data) => api.create(url.FLOORINGADDRECIEPT(hid),data)

export const FLOORINGdeleteReciept = (pk) => api.delete(url.FLOORINGREMOVERECIEPT(pk))

export const RetrieveFLOORINGSpaces = (pk) => api.get(url.FlooringSpaces(pk))

// finishes
export const getFinishes = (pid,parms) => api.get(url.GETFinishes(pid),parms)

export const createFinishes = (pid,data) => api.create(url.GETFinishes(pid),data)

export const deleteFinishes = (id) => api.delete(url.UPDATEFinishes(id))

export const updateFinishes = (id,data) => api.put(url.UPDATEFinishes(id),data)

export const retrieveFinishes = (pk) => api.get(url.UPDATEFinishes(pk))

export const editRetrieveFinishes = (pk) => api.get(url.EDITRETRIEVEFinishes(pk))

export const getFinishesTypes = () => api.get(url.FinishesTYPES)

export const getFinishesPlacementTypes = () => api.get(url.FinishesPLACEMENTTYPES)

export const FinishesaddImage = (hid,data) => api.create(url.FinishesADDIMAGE(hid),data)

export const FinishesdeleteImage = (pk) => api.delete(url.FinishesREMOVEIMAGE(pk))

export const FinishesaddReciept = (hid,data) => api.create(url.FinishesADDRECIEPT(hid),data)

export const FinishesdeleteReciept = (pk) => api.delete(url.FinishesREMOVERECIEPT(pk))

export const RetrieveFinishesSpaces = (pk) => api.get(url.FinishesSpaces(pk))


// insurance

export const getInsurance = (pid,parms) => api.get(url.GETInsurance(pid),parms)

export const createInsurance = (pid,data) => api.create(url.GETInsurance(pid),data)

export const deleteInsurance = (id) => api.delete(url.UPDATEInsurance(id))

export const updateInsurance = (id,data) => api.put(url.UPDATEInsurance(id),data)

export const editRetrieveInsurance = (pk) => api.get(url.UPDATEInsurance(pk))

export const getInsurancePolicyTypes = () => api.get(url.insurancePolicyTYPES)

export const getInsuranceDeductableTypes = () => api.get(url.insuranceDeductableTYPES)

export const retrieveInsurance = (pk) => api.get(url.RetrieveInsurance(pk))

// maintenance 

export const getmaintenance = (pid,parms) => api.get(url.GETmaintenance(pid),parms)

export const createmaintenance = (pid,data) => api.create(url.GETmaintenance(pid),data)

export const deletemaintenance = (id) => api.delete(url.UPDATEmaintenance(id))

export const updatemaintenance = (id,data) => api.put(url.UPDATEmaintenance(id),data)

export const retrievemaintenance = (pk) => api.get(url.UPDATEmaintenance(pk))

export const editRetrievemaintenance = (pk) => api.get(url.EDITRETRIEVEmaintenance(pk))

export const getmaintenanceTypes = () => api.get(url.maintenanceTYPES)

export const getmaintenancePriorites = () => api.get(url.maintenancePRIORITIES)

export const getmaintenanceFrequencies = () => api.get(url.maintenanceFREQUENCIES)

export const maintenanceaddImage = (hid,data) => api.create(url.maintenanceADDIMAGE(hid),data)

export const maintenancedeleteImage = (pk) => api.delete(url.maintenanceREMOVEIMAGE(pk))

export const maintenanceaddReciept = (hid,data) => api.create(url.maintenanceADDRECIEPT(hid),data)

export const maintenancedeleteReciept = (pk) => api.delete(url.maintenanceREMOVERECIEPT(pk))

export const RetrievemaintenanceSpaces = (pk) => api.get(url.maintenanceSpaces(pk))

// notes
export const createNoteBE = (pid,data) => api.create(url.notesEndpoint(pid),data)

export const deleteNoteBE = (pk) => api.delete(url.noteEndpoint(pk))

export const getNotesBE = (pid,parms) => api.get(url.notesEndpoint(pid),parms)

export const updateNoteBE = (id,data) => api.put(url.noteEndpoint(id),data)

export const retrieveNoteBE = (pk) => api.get(url.noteEndpoint(pk))

export const RetrieveNoteSpacesBE = (pk) => api.get(url.noteSpacesEndpoint(pk))

export const RetrieveNoteCategoriesBE = (pid) => api.get(url.noteCategoriesEndpoint(pid))

// reminders
export const createReminderBE = (pid,data) => api.create(url.remindersEndpoint(pid),data)

export const deleteReminderBE = (pk) => api.delete(url.reminderEndpoint(pk))

export const getRemindersBE = (pid,parms) => api.get(url.remindersEndpoint(pid),parms)

export const updateReminderBE = (id,data) => api.put(url.reminderEndpoint(id),data)

export const retrieveReminderBE = (pk) => api.get(url.reminderEndpoint(pk))

export const RetrieveReminderSpacesBE = (pk) => api.get(url.reminderSpacesEndpoint(pk))

export const RetrieveReminderCategoriesBE = (pid) => api.get(url.reminderCategoriesEndpoint(pid))

// files
export const getFilesProperty = (pid,params) => api.get(url.GETFilesProperty(pid),params)

export const addFileProperty = (pid,data) => api.create(url.GETFilesProperty(pid),data)

export const deleteFileProperty = (pk) => api.delete(url.UpdateFileProperty(pk))

export const updateFileProperty = (pk,data) => api.put(url.UpdateFileProperty(pk),data)

export const retrieveFileProperty = (pk) => api.get(url.UpdateFileProperty(pk))

export const getTagsProperty = (pid) => api.get(url.GetFileTagsProperty(pid))

// support

export const submitFeedback = (data) => api.create(url.submitFeedback,data)

export const SupportCaseCreate = (data) => api.create(url.CaseCreateUrl,data)
export const SupportCaseMsgCreate = (id,data) => api.create(url.CaseMsgCreateUrl(id),data)
export const SupportCasesGet = (params) => api.get(url.CasesListUrl,params)  
export const SupportCaseRetrieve = (id) =>  api.get(url.CaseRetrieveUrl(id))
export const SupportCaseFileRetrieve = (id) => api.get(url.supportFileUrl(id))
export const SupportCaseChangeStatus = (id,data) => api.put(url.supportCaseChangeState(id),data)
export const SupportCaseClose = (id) => api.create(url.supportCaseClose(id))

// reports


export const ReportsGet = (pid,params) => api.get(url.allReports(pid),params)
export const ReportRetrieve = (pk) => api.get(url.deleteRetrieveReports(pk))
export const ReportDelete = (pk) => api.delete(url.deleteRetrieveReports(pk))

export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))
export const PropertyWorkbookReportCreate = (pid) => api.create(url.generatePropertyWorkbookReport(pid))
export const ApplianceReportCreate = (pid) => api.create(url.generateApplianceReport(pid))
export const FinishesReportCreate = (pid) => api.create(url.generateFinishesReport(pid))
export const FlooringReportCreate = (pid) => api.create(url.generateFlooringReport(pid))
export const HVACReportCreate = (pid) => api.create(url.generateHVACReport(pid))
export const InventoryReportCreate = (pid) => api.create(url.generateInventoryReport(pid))
export const InsuranceReportCreate = (pid) => api.create(url.generateInsuranceReport(pid))
export const BillsReportCreate = (pid) => api.create(url.generateBillsReport(pid))
export const PropertyElementsReportCreate = (pid) => api.create(url.generatePropertyElementsReport(pid))
export const FloorsReportCreate = (pid) => api.create(url.generateFloorsReport(pid))


// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))
// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))

// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))
// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))
// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))
// export const PropertyReportCreate = (pid) => api.create(url.generatePropertyReport(pid))




// // dashboard

// property elements
export const getAllPropertyElements = (pid,params) => api.get(url.GETPropertyElements(pid),params)
export const getSpacesPropertyElements = (pk) => api.get(url.GETSpacesPropertyElements(pk))
export const DeletePropertyElementsAPI = (pk) => api.delete(url.DeletePropertyElement(pk))

// settings
export const getAllPropertyElementsSettingsTable = (pid) => api.get(url.GETPropertyElementsSettingsTable(pid))
export const getAllPropertyElementsSettingsConfig = (pid) => api.get(url.GETPropertyElementsSettingsConfig(pid))
export const UpdatePropertyElementsConfig = (pid,data) => api.put(url.GETPropertyElementsSettingsConfig(pid),data)

// private photos retrieve

export const  appliancePhoto = (pk) =>api.get(url.appliancePhoto(pk))
export const  applianceReciept = (pk) =>api.get(url.applianceReciept(pk))
export const  finishPhoto = (pk) =>api.get(url.finishPhoto(pk))
export const  finishReciept = (pk) =>api.get(url.finishReciept(pk))
export const  flooringPhoto = (pk) =>api.get(url.flooringPhoto(pk))
export const  flooringReciept = (pk) =>api.get(url.flooringReciept(pk))
export const  levelPhoto = (pk) =>api.get(url.levelPhoto(pk))
export const  HVACPhoto = (pk) =>api.get(url.HVACPhoto(pk))
export const  HVACReciept = (pk) =>api.get(url.HVACReciept(pk))
export const  insurancePhoto = (pk) =>api.get(url.insurancePhoto(pk))
export const  inventoryPhoto = (pk) =>api.get(url.inventoryPhoto(pk))
export const  maintenancePhoto = (pk) =>api.get(url.maintenancePhoto(pk))
export const  maintenanceReciept = (pk) =>api.get(url.maintenanceReciept(pk))
export const  billPhoto = (pk) =>api.get(url.billPhoto(pk))


// portfolio

export const  API_GETPortfolio = (uid) => api.get(url.GETPortfolio(uid))
export const  API_GETPortfolioProfile = (uid,params) => api.get(url.GETPortfolioProfile(uid),params)
export const  API_RetrievePortfolio = (pid) => api.get(url.RetrievePortfolio(pid))
export const  API_DeletePortfolio = (pid) => api.delete(url.RetrievePortfolio(pid))
export const  API_UpdatePortfolio = (pid,data) => api.put(url.RetrievePortfolio(pid),data)
export const  API_PortfolioCreate = (data) => api.create(url.PortfolioCreate,data)
export const  API_CreateAsset = (pid,data) => api.create(url.CreateAsset(pid),data)
export const  API_DeleteAsset = (aid) =>api.delete(url.DeleteAsset(aid))
export const  API_UpdateAsset = (aid,data) => api.put(url.DeleteAsset(aid),data)
export const  API_UpdateAssetOrders = (aid,data) => api.create(url.UpdateAssetOrder(aid),data)
export const  API_ReorderPortfolioRetrieve = () => api.get(url.ReorderPortfolio)
export const  API_ReorderPortfolioUpdate = (data) => api.create(url.ReorderPortfolioPost,data)
