import { useEffect, useRef, useState } from "react";
import { API_GETPortfolioProfile } from "../../helpers/backend_helper";
import { PortfolioItem } from "../Common/portfolio/Portfolio";
import { PaginationWithDetails } from "../Common/pagination";
import { Row, Spinner } from "reactstrap";

export const usePortfolioProfile = (uid, draft_state,refresh ,editPermession) => {
  const [portfolios, setPortfolios] = useState({});
  const [params, setParams] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const mountedRef = useRef();

  useEffect(() => {
    var obj = { ...params, is_draft: draft_state === "Draft" ? 1 : 0 };
    setLoading(true);
    API_GETPortfolioProfile(uid, obj)
      .then((res) => setPortfolios(res))
      .finally(() => setLoading(false));
  }, [uid, params, draft_state]);

  useEffect(() => {
    // console.log("refresh",refresh)
    if (mountedRef.current) {
      setParams({ page: 1 });
    }
    mountedRef.current = true;
  }, [draft_state,refresh]);


  const onPageChange = (page) => {
    if (page !== params.page) {
      setParams({ page: page });
    }
  };

  return (
    <>
      {loading ? (
        <Spinner className="m-auto mt-2" size="xl" />
      ) : (
        (portfolios.results || []).map((item) => <PortfolioItem item={item} edit={editPermession} />)
      )}
      <Row className="justify-content-md-end justify-content-center align-items-center p-4">
        <PaginationWithDetails
          onPageChange={onPageChange}
          numOfPages={portfolios.total_pages}
          showingLength={portfolios.results ? portfolios.results.length : 0}
          currentPage={portfolios.page_num}
          pageSize={portfolios.page_size}
          total={portfolios.count}
        />
      </Row>
    </>
  );
};
