import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { ImageInputSelector } from "../../../../Components/Common/fileSelectComponents/imageWraper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ALLLOWED_EXT_FLOORPLAN } from "../../../../Components/constants/constants";
import {
  PrivateFileWraper,
  ZoomImageComponent,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";
import { width } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { scanSpacesFromID } from "../../../../helpers/backend_helper";
import { toastError } from "../../../../Components/error";
import ScanSpaceEntry from "./ScanSpaceEntry";
import { useParams } from "react-router-dom";

export const ScanSpacesModal = ({ show, toggle }) => {

  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const WraperView = useRef(null);
  const WraperView2 = useRef(null);
  const [wraperWidth,setWraperWidth] = useState(0);
  
  const [scanLoading, setScanLoading] = useState(false);
  const [scan , setScan] = useState({
    id:-1,
    data:[]
  })

  let { id } = useParams();

  const { selectedFiles } = useSelector((state) => ({
    selectedFiles: state.propertyFilesImages.selectedFiles,
  }));

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  function previewSelected() {
    if (selectedFiles.length === 0) {
      toast.error("You need to select your floorplan file");
    } else if (!ALLLOWED_EXT_FLOORPLAN.includes(selectedFiles[0].extension)) {
      toast.error(
        `Your selected file type (${
          selectedFiles[0].extension
        }) isn't supported for scanning, please select file of these types (${ALLLOWED_EXT_FLOORPLAN.join(
          ", "
        )}).`
      );
    } else {
      toggleArrowTab(activeArrowTab + 1);
    }
  }

  function scanClick() {
    if(selectedFiles[0].id != scan.id ){
        setScanLoading(true)
        scanSpacesFromID(selectedFiles[0].id)
        .then((res) => {
            setScan({id:selectedFiles[0].id,data:res})
            toggleArrowTab(activeArrowTab + 1);
        })
        .catch((err) => toastError(err))
        .finally(()=> setScanLoading(false))
    }else{
        toggleArrowTab(activeArrowTab + 1);
    }
  }

  function removeSpaceEntry(idx) {
    const newarr = [...scan.data.slice(0,idx),...scan.data.slice(idx+1)]
    setScan({id:scan.id,data:newarr})
  }

  function setUpdateId(idx,res) {
    const newarr = [...scan.data];
    newarr[idx] = res;
    setScan({id:scan.id,data:newarr})
  }

  function addNewEntry() {
    const newEntry = {"name":""};
    const newarr = [...scan.data];
    newarr.push(newEntry)
    setScan({id:scan.id,data:newarr})
  }

  useEffect(()=>{
    console.log("executed")
    if(WraperView.current && WraperView2.current){
        setWraperWidth(Math.max(WraperView.current.offsetWidth,WraperView2.current.offsetWidth,wraperWidth))
    }else if(WraperView.current){
        setWraperWidth(Math.max(WraperView.current.offsetWidth,wraperWidth))
    }else if(WraperView2.current) {
        setWraperWidth(Math.max(WraperView2.current.offsetWidth,wraperWidth))
    }
  },[WraperView.current,WraperView2.current])

  return (
    <Modal size="xl" className="modal-dialog-scrollable" isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader
        className="p-3"
        toggle={toggle}
      >
        Scan Spaces
      </ModalHeader>
      <ModalBody>
        <div className="step-arrow-nav mb-4">
          <Nav className="nav-pills custom-nav nav-justified" role="tablist">
            <NavItem>
              <NavLink
                href="#"
                id="steparrow-gen-info-tab"
                className={classnames({
                  active: activeArrowTab === 4,
                  done: activeArrowTab <= 6 && activeArrowTab > 3,
                })}
                style={{cursor:'auto'}}
                // onClick={() => {
                //   toggleArrowTab(4);
                // }}
              >
                Select Floorplan Image
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                style={{cursor:'auto'}}
                id="steparrow-gen-info-tab"
                className={classnames({
                  active: activeArrowTab === 5,
                  done: activeArrowTab <= 6 && activeArrowTab > 4,
                })}
                // onClick={() => {
                //   toggleArrowTab(5);
                // }}
              >
                Floorplan Image Preview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                id="steparrow-gen-info-tab"
                className={classnames({
                  active: activeArrowTab === 6,
                  done: activeArrowTab <= 6 && activeArrowTab > 5,
                })}
                style={{cursor:'auto'}}
                // onClick={() => {
                //   toggleArrowTab(6);
                // }}
              >
                Extracted Spaces
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={activeArrowTab}>
          <TabPane id="steparrow-gen-info" tabId={4}>
            <div ref={WraperView} style={{ width: "100%" }}></div>
            <ImageInputSelector multiple={false} />
            <div className="d-flex align-items-start gap-3 mt-4">
              <button
                type="button"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
                data-nexttab="steparrow-description-info-tab"
                onClick={previewSelected}
              >
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Preview Selected Image
              </button>
            </div>
          </TabPane>

          <TabPane id="steparrow-description-info" tabId={5}>
            {scanLoading ? <h4 className="text-muted">Extracting Spaces from your floorplan image, Please Wait...</h4>:null}
            <div ref={WraperView2} style={{ width: "100%" }}>
              {selectedFiles.length > 0 && WraperView.current ? (
                <PrivateFileWraper
                  id={selectedFiles[0].id}
                  module={"privateFile"}
                  ViewComponent={ZoomImageComponent}
                  width={wraperWidth}
                />
              ) : null}
            </div>

            <div className="d-flex align-items-start gap-3 mt-4">
              <button
                type="button"
                className="btn btn-light btn-label previestab"
                onClick={() => {
                  toggleArrowTab(activeArrowTab - 1);
                }}
                disabled={scanLoading}
              >
                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                {scanLoading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Back to Select Image
              </button>
              <button
                type="button"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
                onClick={scanClick}
                data-nexttab="pills-experience-tab"
                disabled={scanLoading}
              >
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                {scanLoading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Scan Floorplan
              </button>
            </div>
          </TabPane>

          <TabPane id="pills-experience" tabId={6}>
          <h4>Spaces</h4>
          <div>
            {scan.data.length >0 && scan.data.map((space,idx) => <ScanSpaceEntry setUpdateId={(id) => setUpdateId(idx,id)} key={idx} removeFunc={() => removeSpaceEntry(idx)} edited={space} id={id} />) }
          </div>
          <div className="d-flex align-items-start gap-3 mt-4">
              <button
                type="button"
                className="btn btn-light btn-label previestab"
                onClick={addNewEntry}
              >
                <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>{" "}
                Add New Entry
              </button>
            </div>
          <div className="d-flex align-items-start gap-3 mt-4">
              <button
                type="button"
                className="btn btn-light btn-label previestab"
                onClick={() => {
                  toggleArrowTab(activeArrowTab - 1);
                }}
              >
                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                Back to Preview Image
              </button>
            </div>

          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};
