import { useNavigate, useParams } from "react-router-dom"
import { ProjectMainPage } from "./ProjectMainPage"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../slices/loading/reducer";
import { API_RetrievePortfolio } from "../../../helpers/backend_helper";
import { toastError } from "../../../Components/error";
import { setProjectFromUpdateRetrieve } from "../../../slices/contractor/createPortfolio/reducer";


export const PortofilioUpdatePage = () => {

    let {id} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        dispatch(loadingActions.setLoading(true));
        API_RetrievePortfolio(id).then((res) => {
            dispatch(setProjectFromUpdateRetrieve(res))
        })
        .catch((err) => {
            // console.log("error",err)
            toastError(err)
            navigate("/")
        })
        .finally(() => dispatch(loadingActions.setLoading(false)))
    },[])

    return <ProjectMainPage breadCrumbTitle="Update Your Project"/>
}