import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Tooltip,
} from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error, toastError } from "../../../../Components/error";
import { useSelector, useDispatch } from "react-redux";

import { loadingActions } from "../../../../slices/loading/reducer";
import axios from "axios";
import { useAddress } from "../../../property/CreateProperty/address";
import { UpdateProfileMethod } from "../../../../helpers/backend_helper";
import { toast } from "react-toastify";
import { profileSuccess } from "../../../../slices/auth/profile/reducer";
import { get_stay_place_update } from "../../../../Components/utils/stay_place";

const EditStayPlace = ({ zips, cities }) => {
  document.title = "Contractor Edit Place | DwellDoc";

  const [addressComponents, getAddress, setAddressState] = useAddress({
    cities: cities,
  });

  const [loading, setLoading] = useState(false);

  const { user, userProfile } = useSelector((state) => ({
    user: state.Login.user,
    userProfile: state.Profile.user,
  }));

  const [zipCode, setzipCode] = useState("");

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  const dispatch = useDispatch();

  function getFormData() {
    var formdata = new FormData();
    const cityAddress = getAddress();

    if (cityAddress.hasState) {
      formdata.append(
        "stay_place",
        cityAddress.city.cityName + ", " + cityAddress.state
      );
    } else {
      formdata.append("stay_place", cityAddress.city.cityName);
    }
    return formdata;
  }

  useEffect(() => {
    if (userProfile && userProfile.stay_place) {
      setAddressState({
        cityName: userProfile.stay_place.city,
        stateName: userProfile.stay_place.state,
        country: userProfile.stay_place.country,
      });
      setzipCode(userProfile.zipCode);
    }
  }, [userProfile]);

  function handleClick() {
    var errs = [];

    checkOrError(zipCode, errs, "you need to add zipCode for your account");
    var address = null;
    try {
      address = getAddress();
    } catch (e) {
      errs.push(e);
    }

    if (errs.length > 0) {
      toastError(errs);
    } else {
      setLoading(true);
      UpdateProfileMethod(user)(user.id, getFormData())
        .then((res) => {
          toast.success("Your place is updated successfully");
          dispatch(
            profileSuccess({
              ...res,
              stay_place: get_stay_place_update(address),
            })
          );
        })
        .catch((err) => toastError(err))
        .finally(() => setLoading(false));
    }
  }


  function zipAutoRefill() {
    const zipdata = zips[zipCode];
    if (zipdata) {
      setAddressState(zipdata);
    } else {
      dispatch(
        createProductFailure(
          "We are sorry this Zip Code doesn't exists in our database. Please select your country, state, and city manually"
        )
      );
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Create Property"
            pageTitle="Properties"
            pageLink={"/properties"}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="checkout-tab">
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardHeader>
                          <h5 className="card-title mb-0">Current Location</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="zipCode-link"
                            >
                              Zip Code
                            </Label>
                            <Row>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="zipCode-link"
                                  name="zipCodeLink"
                                  placeholder="Your Property Zip Code"
                                  value={zipCode}
                                  onChange={(e) => setzipCode(e.target.value)}
                                />
                                <button
                                  className="btn btn-outline-success"
                                  type="button"
                                  id="zipbtn"
                                  onClick={zipAutoRefill}
                                >
                                  <i
                                    style={{ fontSize: "20px" }}
                                    className="bx bx-refresh"
                                  ></i>
                                </button>

                                <Tooltip
                                  isOpen={tooltipOpen}
                                  toggle={() => setTooltipOpen(!tooltipOpen)}
                                  placement="top"
                                  target="zipbtn"
                                >
                                  Auto fill city , state using this zipCode
                                </Tooltip>
                              </div>
                            </Row>
                          </div>

                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="country-link"
                            >
                              Country
                            </Label>
                            <Row>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="country-link"
                                  name="country"
                                  placeholder="Country"
                                  disabled
                                  readOnly
                                  value={"United States"}
                                />
                              </div>
                            </Row>
                          </div>

                          {addressComponents}
                        </CardBody>
                      </Card>
                    </Col>
                    <div className="d-flex align-items-start gap-3 mt-4">
                      <button
                        type="button"
                        className="btn btn-success btn-label right ms-auto nexttab nexttab"
                        data-nexttab="pills-info-desc-tab"
                        onClick={handleClick}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                            Loading...
                          </Spinner>
                        ) : null}
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Update Location
                      </button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export const EditCityWraper = () => {
  const [cities, setcities] = useState(null);
  const [zips, setzips] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.origin;
    // const url = "https://dev.dwelldoc.app";
    dispatch(loadingActions.setLoading(true));
    Promise.all([
      axios.get(url + "/cities.json"),
      axios.get(url + "/zips.json"),
    ]).then((res) => {
      setcities(res[0]);
      setzips(res[1]);
    });
  }, []);

  useEffect(() => {
    if (cities && zips) {
      dispatch(loadingActions.setLoading(false));
    }
  }, [cities, zips]);

  return cities && zips ? (
    <EditStayPlace zips={zips} cities={cities} />
  ) : (
    <div></div>
  );
};
