import { createSlice } from "@reduxjs/toolkit";
import { generateId, moveElement } from "../../../Components/utils/swipe_array";
import { toast } from "react-toastify";

const baseItem = {
  title: "",
  // "isThumbnail":false,
  video_type: "Youtube",
  video_id: "",
  image: [],
  type: "Image",
  size: 0,
  created: false,
};

export const initialState = {
  error: "",
  success: "",
  loading: true,
  btnloading: false,
  assets: [],
  sorted: [],
  thumbnailId: null,
  submitId: null,
  project: null,
  state: "create",
  real_instances: [],
  selected_delete_id: null,
};

const PortfolioCreateSlice = createSlice({
  name: "createPortfolio",
  initialState,
  reducers: {
    resetCreatePortfolioState(state, action) {
      state.error = "";
      state.success = "";
      state.loading = true;
      state.btnloading = false;
      state.assets = [];
      state.sorted = [];
      state.thumbnailId = null;
      state.submitId = null;
      state.project = null;
      state.state = "create";
      state.real_instances = [];
      state.selected_delete_id = null;
    },
    addAsset(state, action) {
      const id = generateId();
      state.assets = [...state.assets, { ...baseItem, id: id }];

      if (!state.thumbnailId) {
        state.thumbnailId = id;
      }
    },
    updateAsset(state, action) {
      const idx = state.assets.findIndex(
        (elem) => elem.id === action.payload.id
      );
      state.assets[idx] = { ...state.assets[idx], ...action.payload };
    },
    deleteAsset(state, action) {
      const index = state.assets.findIndex(
        (elem) => elem.id === action.payload
      );
      state.assets.splice(index, 1);
    },
    swipeAssets(state, action) {
      if (action.payload.oldIndex !== action.payload.newIndex) {
        state.assets = moveElement(
          state.assets,
          action.payload.oldIndex,
          action.payload.newIndex
        );
      }
    },
    thumbnailClick(state, action) {
      if (state.thumbnailId !== action.payload) {
        state.thumbnailId = action.payload;
      }
    },
    setProject(state, action) {
      state.project = action.payload;
      state.sorted = state.assets
        .map((asset, idx) => ({
          ...asset,
          order: idx,
          isThumbnail: state.thumbnailId === asset.id,
        }))
        .sort((a, b) => a.size - b.size);
      if (state.sorted.length === 0) {
        // TODO:empty assets
        // console.log("empty")
        state.state = "update";
        toast.success("Project Created Successfully");
        state.btnloading = false;
      } else {
        const element = state.sorted.shift();
        state.submitId = element;
      }
    },
    setBtnLoading(state, action) {
      state.btnloading = action.payload;
    },
    submit_next(state, action) {
      if (state.state === "create") {
        state.real_instances.push(action.payload);
        state.real_instances.sort((a, b) => a.order - b.order);

        if (state.submitId === null) {
          state.sorted = state.assets.slice().sort((a, b) => a.size - b.size);
        }
        if (state.sorted.length === 0) {
          // TODO:finished
          // console.log("finished")
          state.submitId = null;
          state.state = "update";
          toast.success("Project Created Successfully");
          state.btnloading = false;
          const thumbnailidx = state.real_instances.findIndex((val) => {
            return val.isThumbnail;
          });
          if (thumbnailidx !== -1) {
            state.thumbnailId = state.real_instances[thumbnailidx].id;
          }
          state.assets = state.real_instances;
          state.real_instances = [];
        } else {
          const element = state.sorted.shift();
          state.submitId = element;
        }
      } else if (state.state === "update" || state.state === "reorder") {
        const itmidx = state.assets.findIndex((val) => {
          return val.id == action.payload.id;
        });
        if (itmidx !== -1) {
          state.assets[itmidx] = { ...state.assets[itmidx], ...action.payload };
        }
        if (action.payload.created) {
          toast.success("Asset Created Successfully");
        }
      }
    },
    submit_instance(state, action) {},
    update_project(state, action) {
      state.project = action.payload;
    },
    set_delete_id(state, action) {
      state.selected_delete_id = action.payload;
    },
    delete_success(state, action) {
      state.assets = state.assets.filter(
        (val) => val.real_id !== action.payload
      );
      state.selected_delete_id = null;
      state.btnloading = false;
      toast.success("asset deleted successfully");
    },
    setFormState(state, action) {
      state.state = action.payload;
    },
    reorderSuccess(state, action) {
      state.state = "update";
      state.assets = state.assets.map((val, idx) => ({ ...val, order: idx }));
      toast.success(action.payload);
    },
    reorderCancel(state, action) {
      state.state = "update";
      state.assets.sort((a, b) => a.order - b.order);
    },
    setProjectFromUpdateRetrieve(state, action) {
      state.state = "update";
      state.assets = action.payload.assets.map((val) => ({
        ...val,
        created: true,
        real_id: val.id,
        type: val.image ? "Image" : "Video",
      }));
      // delete action.payload.assets;
      state.project = action.payload;
      const thumbnailidx = state.assets.findIndex((val) => {
        return val.isThumbnail;
      });
      if (thumbnailidx !== -1) {
        state.thumbnailId = state.assets[thumbnailidx].id;
      }
      state.btnloading = false;
    },
  },
});

export const {
  addAsset,
  deleteAsset,
  updateAsset,
  swipeAssets,
  thumbnailClick,
  setProject,
  setBtnLoading,
  submit_next,
  submit_instance,
  update_project,
  set_delete_id,
  delete_success,
  setFormState,
  reorderSuccess,
  reorderCancel,
  setProjectFromUpdateRetrieve,
  resetCreatePortfolioState,
} = PortfolioCreateSlice.actions;

export default PortfolioCreateSlice.reducer;
