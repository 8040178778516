import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Card, CardBody, CardHeader, Col } from "reactstrap";
// import { newsFeed } from "../../common/data";
import {
  API_ReorderPortfolioRetrieve,
  API_ReorderPortfolioUpdate,
} from "../../../helpers/backend_helper";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  compareArrays,
  moveElement,
} from "../../../Components/utils/swipe_array";
import { toast } from "react-toastify";
import { toastError } from "../../../Components/error";

const Handle = SortableHandle(({ tabIndex }) => (
  <div tabIndex={tabIndex} className="flex-shrink-0">
    <i className="mdi mdi-view-sequential" />
  </div>
));

export const ReOrderItem = ({ project, idx }) => {
  return (
    <div
      className={
        "d-flex p-2 bg-light bg-gradient " +
        (idx === 0 ? "align-middle" : "mt-4")
      }
      style={{ zIndex: 10000 }}
    >
      <Handle />
      <div className="flex-grow-1 ms-3">
        <h6 className="mb-1 lh-base">
          <Link to="#" className="text-reset">
            {project.title}
          </Link>
        </h6>
      </div>
    </div>
  );
};

const SortableItem = SortableElement((sortableProps) => {
  const { value: item, idx: index } = sortableProps;
  return <ReOrderItem idx={index} project={item} />;
});

const SortableList = SortableContainer((props) => {
  const { items, ...restProps } = props;
  return (
    <div className="d-flex flex-column">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          idx={index}
          value={item}
          {...restProps}
        />
      ))}
    </div>
  );
});

export function PortfolioReorderModal({ isOpen, toggle }) {
  const [projects, setProjects] = useState(null);
  const [baseArr, setBaseArr] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      API_ReorderPortfolioRetrieve().then((res) => {
        setProjects([...res]);
        setBaseArr([...res]);
      });
    }
  }, [isOpen]);


  const getData = () => {
    return {
      items: projects.map((itm, idx) => ({ id: itm.id, order: idx })),
    };
  };
  const saveChanges = () => {
    const equal = compareArrays(projects, baseArr);
    if (!equal) {
      setLoading(true);
      API_ReorderPortfolioUpdate(getData())
        .then((res) => {
          toast.success("reordered successfully");
          setBaseArr([...projects]);
          setLoading(false);
        })
        .catch((err) => toastError(err));
    } else {
      toast.info("Everything is up to date");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      className="border-0 modal-dialog-scrollable"
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
        Reorder Projects
      </ModalHeader>
      <ModalBody>
        {projects ? (
          <SortableList
            axis="y"
            items={projects}
            shouldUseDragHandle={true}
            useDragHandle
            onSortEnd={({ oldIndex, newIndex }) => {
              setProjects((oldProjects) => [
                ...moveElement(oldProjects, oldIndex, newIndex),
              ]);
            }}
          />
        ) : (
          <Spinner className="m-auto mt-2" size="xl" />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!projects || loading}
          onClick={() => setProjects([...baseArr])}
          variant="secondary"
        >
          {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
          Reset
        </Button>
        <Button
          onClick={saveChanges}
          disabled={!projects || loading}
          variant="primary"
        >
          {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
}

// export default NewsFeed;
