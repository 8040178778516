import {
  API_DeleteAsset,
  API_DeletePortfolio,
  API_PortfolioCreate,
  API_UpdateAssetOrders,
  API_UpdatePortfolio,
} from "../../../helpers/backend_helper";
import {
  delete_success,
  setBtnLoading,
  set_delete_portfolio
} from "./reducer";
import { toastError } from "../../../Components/error";
import { toast } from "react-toastify";

export const deletePortfolioThunk = (p_id) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  API_DeletePortfolio(p_id)
    .then((res) => {
      dispatch(delete_success());
    })
    .catch((err) => toastError(err))
    .finally(() => dispatch(setBtnLoading(false)));
};

// export const reorderAssetsThunk = (project_id, data) => async (dispatch) => {
//   dispatch(setBtnLoading(true));
//   API_UpdateAssetOrders(project_id, data)
//     .then((res) => {
//       dispatch(reorderSuccess(res));
//     })
//     .catch((err) => toastError(err))
//     .finally(() => dispatch(setBtnLoading(false)));
// };
