import {
  API_DeleteAsset,
  API_PortfolioCreate,
  API_UpdateAssetOrders,
  API_UpdatePortfolio,
} from "../../../helpers/backend_helper";
import {
  delete_success,
  reorderSuccess,
  setBtnLoading,
  setProject,
  update_project,
} from "./reducer";
import { toastError } from "../../../Components/error";
import { toast } from "react-toastify";

export const createProjectThunk = (data) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  API_PortfolioCreate(data)
    .then((res) => dispatch(setProject(res)))
    .catch((err) => {
      toastError(err);
      dispatch(setBtnLoading(false));
    });
};

export const updateProjectThunk = (project, data) => async (dispatch) => {
  API_UpdatePortfolio(project.id, data)
    .then((res) => {
      dispatch(update_project(res));
      toast.success("Project Updated Successfully");
    })
    .catch((err) => toastError(err));
};

export const deleteAssetThunk = (asset_id, callback) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  API_DeleteAsset(asset_id)
    .then((res) => {
      dispatch(delete_success(asset_id));
    })
    .catch((err) => toastError(err))
    .finally(() => dispatch(setBtnLoading(false)));
};

export const reorderAssetsThunk = (project_id, data) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  API_UpdateAssetOrders(project_id, data)
    .then((res) => {
      dispatch(reorderSuccess(res));
    })
    .catch((err) => toastError(err))
    .finally(() => dispatch(setBtnLoading(false)));
};
