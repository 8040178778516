import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useButtonFileUpload } from "../../Components/Hooks/buttonFileUpload";
import { useState } from "react";
import { SupportCaseCreate } from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { toastError } from "../../Components/error";

export const CreateSupportModal = ({ isOpen, toggle , onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [filesButton, filesModal, getAttachements] =
    useButtonFileUpload(loading);

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      title: "",
      content: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      content: Yup.string().required(
        "Please Enter Detailed Description Of Your Case"
      ),
    }),
    onSubmit: (values) => {
      var formdata = new FormData();
      formdata.append("content", values["content"]);
      formdata.append("tittle", values["title"]);
      var attachements = getAttachements();
      for (let file of attachements) {
        formdata.append("attachements", file.file);
      }
      setLoading(true);
      SupportCaseCreate(formdata)
        .then((res) => {
          toast.success("Support Case is created successfully");

          
          onSuccess();
        })
        .catch((err) => toastError(err))
        .finally(() => setLoading(false));
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      className="border-0 modal-dialog-scrollable"
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
        Add Support Ticket
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          {filesModal}
          <Row className="g-3">
            <Col lg={12}>
              <div>
                <Label htmlFor="tasksTitle-field" className="form-label">
                  Title
                </Label>
                <Input
                  name="title"
                  id="tasksTitle-field"
                  className="form-control"
                  placeholder="Enter Title"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="client_nameName-field" className="form-label">
                  Detailed Description
                </Label>
                <Input
                  name="content"
                  type="textarea"
                  rows="10"
                  id="client_nameName-field"
                  placeholder="Detailed Description Of Your Case "
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.content || ""}
                  invalid={
                    validation.touched.content && validation.errors.content
                      ? true
                      : false
                  }
                />
                {validation.touched.content && validation.errors.content ? (
                  <FormFeedback type="invalid">
                    {validation.errors.content}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>{filesButton}</Col>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              onClick={toggle}
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Close
            </button>
            <button
              disabled={loading}
              type="submit"
              className="btn btn-success"
              id="add-btn"
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Add Ticket
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
