import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const initialState = {
  delete_portfolio_id:null,
  btnloading:false,
  refresh: false,
  selected_portfolio_id:null
};

const PortfolioListSlice = createSlice({
  name: "PortfolioList",
  initialState,
  reducers: { 
    set_delete_portfolio(state, action) {
      state.delete_portfolio_id = action.payload;
    },
    setBtnLoading(state, action) {
      state.btnloading = action.payload;
    },
    refreshPortfolio(state,action){
      state.refresh = !state.refresh;
    },
    delete_success(state,action){
      state.refresh = !state.refresh;
      state.delete_portfolio_id = null;
      toast.success("Deleted successfully")
    },
    set_selected_portfolio_id(state,action){
      state.selected_portfolio_id = action.payload
    }
  }
});

export const {
  set_delete_portfolio,
  refreshPortfolio,
  delete_success,
  setBtnLoading,
  set_selected_portfolio_id
} = PortfolioListSlice.actions

export default PortfolioListSlice.reducer;