import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Col,
  Spinner,
  Input,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import { Error } from "../../../../Components/error";

import { CreateForm } from "../upload/createForm";
import { useDispatch, useSelector } from "react-redux";
import { resetMessages } from "../../../../slices/property/capture/reducer";
import { retrieveCapture } from "../../../../helpers/backend_helper";

const UpdateModal = ({
  edited,
  toggle,
  onSuccess = () => {},
  // success,
  // error,
}) => {

  const {
    error,
    success,
  } = useSelector((state) => ({
    error: state.captureUpload.error,
    success: state.captureUpload.success,
  }));

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(edited){
      dispatch(resetMessages())
    }
  }, [edited]);

  return (
    <Modal
      id="UpdateModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      size="xl"
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Capture Edit
      </ModalHeader>

      {success ? <Alert color="success">{success}</Alert> : null}

      {error ? <Error error={error} /> : null}

      <ModalBody>
        <CreateForm selectedCapture={edited} id={edited? edited.id:null} onSuccess={onSuccess}/>
      </ModalBody>
    </Modal>
  );
};

export function UpdateCaptureWraper({ edited, toggle, onSuccess = () => {}})  {

  const [inst , setInst] = useState(null);

  useEffect(()=>{
    if(edited){
      retrieveCapture(edited.id).then((res)=> setInst(res))
    }else{
      setInst(null)
    }
  },[edited])


  return (
    <UpdateModal
     edited={edited ? inst: null}
     toggle={toggle}
     onSuccess={onSuccess}
    />    
  )
}

export default UpdateModal;
