import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { useEffect, useState } from "react";
import { FileWithProgressbar } from "../../../../Components/Common/imageWithProgressbar/imageComponent";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshDropdown } from "../../../../slices/property/propertyFiles/reducer";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const UploadImageComponent = ({show,toggle,onSuccess,resetOnShow=false}) => {
  
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { selectedProperty, user } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    user: state.Login.user,
  }));

  // const dispatch = useDispatch()

  useEffect(() => {
    if(resetOnShow){
      setFiles([]);
    }
  }, [show]);

  const onStartUploading = () => {
    setUploading(true);
  };
  const OnSuccessWraper = () => {
    setUploading(false);
    if (onSuccess)
      onSuccess()
  };

  return (
    <>
      <div className="mb-3">
        <h3>File Preview</h3>
        <div style={{ position: "relative" }}>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={true}
            // acceptedFileTypes={["image/png", "image/jpeg",]}
            labelIdle={"Upload Your File(s): Drag here or Click to browse"}
            name="files"
            className="filepond filepond-input-multiple"
          />
          {uploading ? (
            <div className="file-pond-spinner-overlay">
              <div className="spinner-wraper">
                {" "}
                <Spinner />{" "}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {files.length > 0 ? (
        <div className="mt-5">
          <h3>Entries</h3>
          <div>
            {files.map((file, idx) => (
              <FileWithProgressbar
                onError={() => setUploading(false)}
                onStartUploading={onStartUploading}
                onSuccess={OnSuccessWraper}
                key={file.id}
                file={file}
                token={user.token}
                pid={selectedProperty.id}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const CreateImageModal = ({ show, toggle, onSuccess }) => {
  return (
    <Modal className="modal-dialog-scrollable" size="xl" isOpen={show} toggle={toggle}>
      <ModalHeader className="p-3" toggle={toggle}>
        File Upload
      </ModalHeader>
      <ModalBody>
        <UploadImageComponent show={show} toggle={toggle} onSuccess={onSuccess} />
      </ModalBody>
    </Modal>
  );
};
