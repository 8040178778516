import { Button, Col, Row } from "reactstrap";
import { CardSelectComp } from "../../../Components/Common/card-select/CardSelect";
import ContractorCardImage from "../../../assets/images/contractor-select-icon.png";
import PropertyOwnerImage from "../../../assets/images/property-owner-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSignUp2 } from "../../../slices/auth/register/reducer";

export const RoleSelectForm = ({ onNext }) => {
  const { formikState } = useSelector((state) => ({
    formikState: state.Account.signup2,
  }));

  const dispatch = useDispatch();

  const onClick = (role) => {
    dispatch(setSignUp2({ ...formikState, role: role }));
  };

  return (
    <>
      <h4 className="m-2 mb-4">Account Type:</h4>
      <div className="d-flex justify-content-around flex-wrap">
        <CardSelectComp
          img={ContractorCardImage}
          title={"Contractor"}
          clicked={formikState.role === "contractor"}
          onClick={() => onClick("contractor")}
        />
        <CardSelectComp
          img={PropertyOwnerImage}
          title={"Property Owner"}
          clicked={formikState.role === "property owner"}
          onClick={() => onClick("property owner")}
        />
      </div>
      <div className="mt-4">
        <Button
          onClick={onNext}
          disabled={!formikState.role}
          color="success"
          className="w-100"
        >
          Next
        </Button>
      </div>
    </>
  );
};
