import React, { useState, useEffect } from "react";
import {
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";

const ReviewItem = () => {
  return (
    <div className="bg-light px-3 py-2 rounded-2">
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <div className="fs-16 align-middle text-warning">
            <i className="ri-star-fill"></i> <i className="ri-star-fill"></i>{" "}
            <i className="ri-star-fill"></i> <i className="ri-star-fill"></i>{" "}
            <i className="ri-star-half-fill"></i>
          </div>
        </div>
        <div className="flex-shrink-0">
          <h6 className="mb-0">4.5 out of 5</h6>
        </div>
      </div>
    </div>
  );
};

export const WorkExperienceListItem = ({ card }) => {
  return (
    <div className="pb-1 task-list">
      <div className="card task-box" id="uptask-1">
        <CardBody>
          <Link to="#" className="text-muted fw-medium fs-14 flex-grow-1 ">
            {card.cardId}
          </Link>
          <UncontrolledDropdown className="float-end">
            <DropdownToggle className="arrow-none" tag="a" color="white">
              <i className="ri-more-fill"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem className="edittask-details">View</DropdownItem>
              <DropdownItem
                className="edittask-details"
                //   onClick={() =>
                // handleCardEdit(card, line)
                //   }
              >
                Edit
              </DropdownItem>
              <DropdownItem
                className="deletetask"
                //   onClick={() =>
                // onClickDelete(card)
                //   }
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="mb-3">
            <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
              <Link to="#" className="d-block" id="task-name">
                {card.title}
              </Link>
            </h6>
          </div>
          {/* rate */}

          <p className="text-muted">{card.text}</p>

          <div className="mb-3"></div>
        </CardBody>
        {/* bottom */}
        <div className="card-footer border-top-dashed">
          <div className="d-flex">
            <div className="flex-grow-1">
              <span className="text-muted">
                <i className="ri-time-line align-bottom"></i>
                {card.date}
              </span>
            </div>
            <div className="flex-shrink-0">
              <ul className="link-inline mb-0">
                <li className="list-inline-item">
                  <ReviewItem />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
