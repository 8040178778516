import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

import product1 from "../../../assets/images/products/img-1.png";
import product6 from "../../../assets/images/products/img-6.png";
import product8 from "../../../assets/images/products/img-8.png";

import { productDetailsWidgets, reviews } from "../../../common/data/ecommerce";

import { Swiper, SwiperSlide } from "swiper/react";
import classnames from "classnames";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

import SwiperCore, { FreeMode, Navigation, Thumbs, Pagination } from "swiper";
import { Link } from "react-router-dom";
import Linkify from "linkify-react";
import { API_RetrievePortfolio } from "../../../helpers/backend_helper";

SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);

function AssetDisplay({ asset }) {
  return asset.video_type === "Vimeo" ? (
    <iframe
      src={`https://player.vimeo.com/video/${asset.video_id}`}
      frameborder="0"
      allowFullScreen="allowFullScreen"
      width="100%"
      height="100%"
      data-ready="true"
    ></iframe>
  ) : asset.video_type === "Youtube" ? (
    <iframe
      src={`https://www.youtube.com/embed/${asset.video_id}`}
      className=""
      frameborder="0"
      allowFullScreen="allowFullScreen"
      width="100%"
      height="100%"
    />
  ) : (
    <img style={{ width: "100%", height: "100%" }} src={asset.image} alt="" />
  );
}

function ProjectDetailView({ project, editPermession }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [ttop, setttop] = useState(false);
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = (swiperCore) => {
    const newIndex = swiperCore.activeIndex;
    // Use newIndex in your component
    setActiveIndex(newIndex);
  };

  return (
    <Row>
      <Col lg={12}>
        <Row className="gx-lg-5">
          <Col sm={12} className="mx-auto">
            <div className="product-img-slider sticky-side-div">
              <Swiper
                onActiveIndexChange={onActiveIndexChange}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                pagination={{
                  type: "fraction",
                }}
                className="swiper product-thumbnail-slider p-2 rounded bg-light"
                spaceBetween={4}
                slidesPerView={1}
                height="auto"
              >
                <div className="swiper-wrapper ">
                  {/* <div className="project-swiper-wraper"> */}
                  {(project.assets || []).map((asset) => (
                    <SwiperSlide>
                      <div
                        class="video-wrapper"
                        style={{
                          width: "100%",
                          height: "500px",
                          objectFit: "contain",
                        }}
                      >
                        <AssetDisplay asset={asset} />
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* </div> */}
                </div>
              </Swiper>
              <Linkify>
                <p className="text-muted">
                  {(project.assets || [])[activeIndex]
                    ? (project.assets || [])[activeIndex].title
                    : ""}
                </p>
              </Linkify>
            </div>
          </Col>

          <Col className="pt-2" sm={12}>
            <div className="mt-2">
              <div className="d-flex">
                <div style={{ position: "relative" }} className="flex-grow-1">
                  <h4 style={{ position: "absolute", bottom: "0" }}>
                    {project.title}
                  </h4>
                  <div className="hstack gap-3 flex-wrap">
                    {/* <div className="text-muted">
                              Published :{" "}
                              <span className="text-body fw-medium">
                                26 Mar, 2021
                              </span>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              Published :{" "}
                              <span className="text-body fw-medium">
                                26 Mar, 2021
                              </span>
                            </div> */}
                  </div>
                </div>
                <div className="flex-shrink-0">
                  {editPermession ? (
                    <div>
                      <Tooltip
                        placement="top"
                        isOpen={ttop}
                        target="TooltipTop"
                        toggle={() => {
                          setttop(!ttop);
                        }}
                      >
                        Edit
                      </Tooltip>
                      <a
                        href="#"
                        id="TooltipTop"
                        className="btn btn-light"
                        onClick={() =>
                          navigate(`/portfolio/project/${project.id}/update`)
                        }
                      >
                        <i className="ri-pencil-fill align-bottom"></i>
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-4 text-muted">
                <h5 className="fs-15">Description :</h5>
                <Linkify>
                  <p>{project.description}</p>
                </Linkify>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export function ProjectDetailModal({ projectId, toggle, editPermession }) {
  const [project, setProject] = useState(null);

  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();

  const handleRemove = () => {
    navigate({ pathname: pathname });
    toggle();
  };

  useEffect(() => {
    if (projectId) {
      API_RetrievePortfolio(projectId).then((res) => setProject(res));
    }
  }, [projectId]);

  return (
    <Modal
      isOpen={projectId}
      toggle={handleRemove}
      centered
      size="lg"
      className="border-0 modal-dialog-scrollable"
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={handleRemove} className="p-3 bg-soft-info">
        Project Details
      </ModalHeader>
      <ModalBody>
        {project ? (
          <ProjectDetailView
            editPermession={editPermession}
            project={project}
          />
        ) : (
          <Spinner className="m-auto mt-2" size="xl" />
        )}
      </ModalBody>
    </Modal>
  );
}

export default ProjectDetailView;
