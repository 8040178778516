import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

//Images
import profileBg from "../../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../../../assets/images/users/avatar-8.jpg";

import smallImage2 from "../../../../assets/images/small/img-2.jpg";
import smallImage3 from "../../../../assets/images/small/img-3.jpg";
import smallImage4 from "../../../../assets/images/small/img-4.jpg";
import smallImage5 from "../../../../assets/images/small/img-5.jpg";
import smallImage6 from "../../../../assets/images/small/img-6.jpg";
import smallImage7 from "../../../../assets/images/small/img-7.jpg";
import smallImage9 from "../../../../assets/images/small/img-9.jpg";

import { projects, document } from "../../../../common/data";
import { getContractorProfile } from "../../../../helpers/backend_helper";
import { toastError } from "../../../../Components/error";
import { useDispatch, useSelector } from "react-redux";
import Linkify from "linkify-react";
import ImageOrLoad from "../../../../Components/Common/ImageOrLoad";
import { PortfolioItem } from "../../../../Components/Common/portfolio/Portfolio";
import { usePortfolioProfile } from "../../../../Components/Hooks/usePortfolioProfile";
import swapImage from "../../../../assets/images/icons/swap.png";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import {
  set_delete_portfolio,
  set_selected_portfolio_id,
} from "../../../../slices/contractor/portfolioList/reducer";
import { deletePortfolioThunk } from "../../../../slices/contractor/portfolioList/thunk";
import { ProjectDetailModal } from "../../projects/ProjectDetails";
import { PortfolioReorderModal } from "../../projects/ReorderModal";
import { resetCreatePortfolioState } from "../../../../slices/contractor/createPortfolio/reducer";
import { format_stay_place } from "../../../../Components/utils/stay_place";
import { WorkExperienceListItem } from "../../../../Components/Common/workExpirence/WorkExperienceListItem";

const cardTrial = {
  title:
    "Extend Cypress End to End Testing to more workflows (React/MUI/Django Stack)",
  text: "I can only recommend Ahmed. He was very good at independent problem-solving but he also asked questions at the right moment when stuck due to problems on our side before wasting time.",
  date: "Aug 24, 2022 - Jan 19, 2023",
};

const ConrtactorProfilePage = () => {
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("1");
  const [projectsTab, setProjectsTab] = useState("Published");

  const [profile, setProfile] = useState({});

  const navigate = useNavigate();

  const {
    user,
    delete_portfolio_id,
    btnLoading,
    refreshPortfolioList,
    selected_portfolio_id,
  } = useSelector((state) => ({
    user: state.Login.user,
    delete_portfolio_id: state.portfolioList.delete_portfolio_id,
    btnLoading: state.portfolioList.btnloading,
    refreshPortfolioList: state.portfolioList.refresh,
    selected_portfolio_id: state.portfolioList.selected_portfolio_id,
  }));

  const dispatch = useDispatch();

  let { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get("project_id");
    if (projectId) {
      dispatch(set_selected_portfolio_id(projectId));
    } else {
      dispatch(set_selected_portfolio_id(null));
    }
  }, [location]);

  const editPermession = user.id == id;

  const PortfolioComponent = usePortfolioProfile(
    id,
    projectsTab,
    refreshPortfolioList,
    editPermession
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };

  const toggleProjectsTab = (tab) => {
    if (projectsTab !== tab) {
      setProjectsTab(tab);
    }
  };

  useEffect(() => {
    if (id) {
      getContractorProfile(id)
        .then((res) => setProfile(res))
        .catch((err) => toastError(err));
    }
  }, []);

  const [reorder, setreorder] = useState(false);

  document.title = "Contractor Profile | DwellDoc";
  return (
    <React.Fragment>
      <ProjectDetailModal
        projectId={selected_portfolio_id}
        toggle={() => dispatch(set_selected_portfolio_id(null))}
        editPermession={editPermession}
      />
      <DeleteModal
        show={delete_portfolio_id}
        onDeleteClick={() =>
          dispatch(deletePortfolioThunk(delete_portfolio_id))
        }
        onCloseClick={() => dispatch(set_delete_portfolio(null))}
        isLoading={btnLoading}
      />
      <PortfolioReorderModal
        isOpen={reorder}
        toggle={() => setreorder(false)}
      />
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg"></div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              {/* <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={avatar2||""} alt="user-img"
                                        className="img-thumbnail rounded-circle"
                                        
                                        
                                        />
                                </div>
                            </div> */}
              <div className="col-auto">
                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                  <ImageOrLoad
                    src={profile.profile_picture || avatar1}
                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile"
                  />
                </div>
              </div>
              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {profile.firstName + " " + profile.lastName || ""}
                  </h3>
                  <p className="text-white-75">{profile.title || ""}</p>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                      {/* {profile.city +
                        ", " +
                        profile.state +
                        ", " +
                        profile.country} */}
                      {format_stay_place(profile.stay_place) ||
                        "Location Not Provided"}
                    </div>
                    {/* <div><i
                                            className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>Themesbrand
                                        </div> */}
                  </div>
                </div>
              </Col>

              <Col xs={12} className="col-lg-auto order-last order-lg-0">
                <Row className="text text-white-50 text-center">
                  {/* <Col xs={4}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">10$</h4>
                      <p className="fs-14 mb-0">Hourly Rate</p>
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className="p-2">
                      <h4 className="text-white mb-1">10 Jobs</h4>
                      <p className="fs-14 mb-0">Total Jobs</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav
                    pills
                    className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Overview
                        </span>
                      </NavLink>
                    </NavItem>
                    {/*<NavItem>
                      <NavLink
                        href="#activities"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Activities
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#projects"
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Projects
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#documents"
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Documents
                        </span>
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  {editPermession ? (
                    <div className="flex-shrink-0">
                      <Link to="/" className="btn btn-success">
                        <i className="ri-edit-box-line align-bottom"></i> Edit
                        Profile
                      </Link>
                    </div>
                  ) : null}
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Row>
                      <Col xxl={3}>
                        {editPermession && (
                          <Card>
                            <CardBody>
                              <h5 className="card-title mb-5">
                                Complete Your Profile
                              </h5>
                              <Progress
                                value={30}
                                color="danger"
                                className="animated-progess custom-progress progress-label"
                              >
                                <div className="label">30%</div>{" "}
                              </Progress>
                            </CardBody>
                          </Card>
                        )}

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">Info</h5>
                            <div className="table-responsive">
                              <Table className="table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      First Name:
                                    </th>
                                    <td className="text-muted">
                                      {profile.firstName || ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Last Name:
                                    </th>
                                    <td className="text-muted">
                                      {profile.lastName || ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Mobile:
                                    </th>
                                    <td className="text-muted">
                                      {profile.contactNumber || ""}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="ps-0" scope="row">
                                      Location:
                                    </th>
                                    <td className="text-muted">
                                      {/* {profile.city +
                                        ", " +
                                        profile.state +
                                        ", " +
                                        profile.country} */}
                                      {format_stay_place(profile.stay_place) ||
                                        "Location Not Provided"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xxl={9}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">About</h5>
                            <Linkify>
                              <p className="text-muted show-white-space">
                                {profile.description || ""}
                              </p>
                            </Linkify>
                          </CardBody>
                        </Card>

                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 me-2">
                                  Work History
                                </h4>
                                <div className="flex-shrink-0 ms-auto">
                                  <Nav
                                    className="justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                    role="tablist"
                                  >
                                    <NavItem>
                                      <NavLink
                                        href="#"
                                        className={classnames({
                                          active: activityTab === "1",
                                        })}
                                        onClick={() => {
                                          toggleActivityTab("1");
                                        }}
                                      >
                                        Completed Jobs
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        href="#"
                                        className={classnames({
                                          active: activityTab === "2",
                                        })}
                                        onClick={() => {
                                          toggleActivityTab("2");
                                        }}
                                      >
                                        In Progress Jobs
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <TabContent
                                  activeTab={activityTab}
                                  className="text-muted"
                                >
                                  <TabPane tabId="1">
                                    {" "}
                                    <WorkExperienceListItem
                                      card={cardTrial}
                                    />{" "}
                                    <WorkExperienceListItem card={cardTrial} />{" "}
                                    <WorkExperienceListItem card={cardTrial} />{" "}
                                  </TabPane>
                                  <TabPane tabId="2"></TabPane>
                                </TabContent>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={12}>
                            <Card>
                              <CardHeader className="border-0">
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center flex-grow-1">
                                    <h4 className="card-title mb-0">
                                      Projects
                                    </h4>
                                    {editPermession ? (
                                      <>
                                        <button
                                          className="btn-info rounded-pill shadow-lg btn btn-icon btn-light p-1 m-1"
                                          onClick={() => {
                                            dispatch(
                                              resetCreatePortfolioState()
                                            );
                                            navigate({
                                              pathname: "/project/create",
                                            });
                                          }}
                                        >
                                          {" "}
                                          <i className="ri-add-line fs-17" />{" "}
                                        </button>
                                        <button
                                          onClick={() => setreorder(true)}
                                          className="btn-info rounded-pill shadow-lg btn btn-icon btn-light p-1 m-1"
                                        >
                                          <img
                                            src={swapImage}
                                            className="up-down"
                                          />{" "}
                                        </button>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="flex-shrink-0 ms-auto">
                                    {editPermession ? (
                                      <Nav
                                        className="justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist"
                                      >
                                        <NavItem>
                                          <NavLink
                                            href="#"
                                            className={classnames({
                                              active:
                                                projectsTab === "Published",
                                            })}
                                            onClick={() => {
                                              toggleProjectsTab("Published");
                                            }}
                                          >
                                            Published
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            href="#"
                                            className={classnames({
                                              active: projectsTab === "Draft",
                                            })}
                                            onClick={() => {
                                              toggleProjectsTab("Draft");
                                            }}
                                          >
                                            Draft
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    ) : null}
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <Row>{PortfolioComponent}</Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConrtactorProfilePage;
