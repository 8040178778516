export function getVimeoId(url) {
  const regex = /https?:\/\/(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/\d+\/video\/|video\/|)(\d+)/i;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function getYoutubeId(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|\/embed\/))([^\?&]+)/i;
  const match = url.match(regex);
  return match ? match[1] : null;
}