import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  FormFeedback,
  Input,
  Button,
  Alert,
  Spinner,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";

import AuthSlider from "../authCarousel";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { registerUser, apiError, resetRegisterFlag } from "../../slices/thunks";

import {
  registerUser,
  apiError,
  resetRegisterFlag,
} from "../../../slices/auth/register/thunk";

import { setSignUp1 } from "../../../slices/auth/register/reducer";
import { checkEmailRedux } from "../../../slices/auth/register/thunk";

export const SignUpForm1 = ({ onSubmit, goBack }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmShow, setconfirmShow] = useState(false);

  const dispatch = useDispatch();

  const { error, success, formikState, loading } = useSelector((state) => ({
    success: state.Account.success,
    error: state.Account.error,
    formikState: state.Account.signup1,
    // registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: formikState,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      email: Yup.string().email(),
      name: Yup.string()
        // .matches(RegExp('^[aA-zZ\s]+$'), "Only alphabets are allowed for this field ")
        .required("Please enter the required field"),
      confirm_password: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Confirm Password Isn't Match"
        ),
      }),
    }),
    onSubmit: (values) => {
      dispatch(
        checkEmailRedux(values.email, () => {
          dispatch(setSignUp1(values));
        })
      );
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      <div className="mb-3">
        <label htmlFor="useremail" className="form-label">
          Email <span className="text-danger">*</span>
        </label>

        <Input
          type="email"
          className="form-control"
          id="email-input"
          placeholder="Enter email address"
          name="email"
          value={validation.values.email}
          onBlur={validation.handleBlur}
          onChange={validation.handleChange}
          invalid={
            validation.errors.email && validation.touched.email ? true : false
          }
        />

        {validation.errors.email && validation.touched.email ? (
          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
        ) : null}
      </div>
      <div className="mb-3">
        <label htmlFor="username" className="form-label">
          Username <span className="text-danger">*</span>
        </label>

        <Input
          type="text"
          className="form-control"
          id="name-input"
          placeholder="Enter name"
          name="name"
          value={validation.values.name}
          onBlur={validation.handleBlur}
          onChange={validation.handleChange}
          invalid={
            validation.errors.name && validation.touched.name ? true : false
          }
        />

        {validation.errors.name && validation.touched.name ? (
          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="password-input">
          Password
        </label>
        <div className="position-relative auth-pass-inputgroup">
          <Input
            type={passwordShow ? "text" : "password"}
            className="form-control pe-5 password-input"
            placeholder="Enter password"
            id="password-input"
            name="password"
            value={validation.values.password}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.password && validation.touched.password
                ? true
                : false
            }
          />
          {validation.errors.password && validation.touched.password ? (
            <FormFeedback type="invalid">
              {validation.errors.password}
            </FormFeedback>
          ) : null}
          <Button
            color="link"
            onClick={() => setPasswordShow(!passwordShow)}
            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
          >
            <i className="ri-eye-fill align-middle"></i>
          </Button>
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="password-input">
          Confirm Password
        </label>
        <div className="position-relative auth-pass-inputgroup">
          <Input
            name="confirm_password"
            type={confirmShow ? "text" : "password"}
            placeholder="Confirm Password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirm_password || ""}
            invalid={
              validation.touched.confirm_password &&
              validation.errors.confirm_password
                ? true
                : false
            }
          />
          {validation.touched.confirm_password &&
          validation.errors.confirm_password ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.confirm_password}</div>
            </FormFeedback>
          ) : null}
          <Button
            color="link"
            onClick={() => setconfirmShow(!confirmShow)}
            className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
          >
            <i className="ri-eye-fill align-middle"></i>
          </Button>
        </div>
      </div>

      <div className="mb-4">
        <p className="mb-0 fs-12 text-muted fst-italic">
          By registering you agree to the DwellDoc{" "}
          <Link
            to="#"
            className="text-primary text-decoration-underline fst-normal fw-medium"
          >
            Terms of Use
          </Link>
        </p>
      </div>

      <div id="password-contain" className="p-3 bg-light mb-2 rounded">
        <h5 className="fs-13">Password must contain:</h5>
        <p id="pass-length" className="invalid fs-12 mb-2">
          Minimum <b>8 characters</b>
        </p>
        <p id="pass-lower" className="invalid fs-12 mb-2">
          At <b>lowercase</b> letter (a-z)
        </p>
        <p id="pass-upper" className="invalid fs-12 mb-2">
          At least <b>uppercase</b> letter (A-Z)
        </p>
        <p id="pass-number" className="invalid fs-12 mb-0">
          A least <b>number</b> (0-9)
        </p>
      </div>

      <p
        onClick={() => {
          dispatch(setSignUp1(validation.values));
          goBack();
        }}
        style={{ cursor: "pointer" }}
        className="fw-semibold text-primary text-decoration-underline"
      >
        goBack
      </p>

      <div className="mt-4">
        <Button
          disabled={success || error || loading ? true : false}
          color="success"
          className="w-100"
          type="submit"
        >
          {loading ? (
            <Spinner size="sm" className="me-2">
              {" "}
              Loading...{" "}
            </Spinner>
          ) : null}
          Next
        </Button>
      </div>

      {/* <div className="mt-4 text-center">
        <div className="signin-other-title">
            <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
        </div>

        <div>
            <button type="button" className="btn btn-primary btn-icon waves-effect waves-light me-1"><i className="ri-facebook-fill fs-16"></i></button>
            <button type="button" className="btn btn-danger btn-icon waves-effect waves-light me-1"><i className="ri-google-fill fs-16"></i></button>
            <button type="button" className="btn btn-dark btn-icon waves-effect waves-light me-1"><i className="ri-github-fill fs-16"></i></button>
            <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
        </div>
    </div> */}
    </form>
  );
};
