import React, { useEffect, useState } from "react";
import {
  Spinner,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  updateImage,
  getSpaceSelectList,
  updateFileProperty,
} from "../../../../helpers/backend_helper";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";
import { usePropertyTags } from "../../../../Components/Hooks/propertyTags";

const UpdateFileModal = ({ edited, toggle, onSuccess = () => {} }) => {
  const id = edited ? edited.id : null;

  const [msg, setmsg] = useState({ err: false, msg: null });
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(null);

  const [selectTagsDropdown, selectedTag , setTagVal , getTagVal] = usePropertyTags(edited);

  useEffect(() => {
    if (edited) {
      setName(edited.name);
      setTagVal(edited.tag)
    }
    setmsg({ err: false, msg: null });
  }, [edited]);

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var data = {}
    if(name && name !== edited.name){
      data["name"] = name
    }
    const tag = getTagVal()
    if(tag && tag !== edited.tag){
      data["tag"] = tag
    }

    return data;
  }

  function submit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Image ");

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = getFormdata();
      const length = Object.keys(data).length;
      if(length === 0){
        setmsg({ err: false, msg: "Everything is up to date" });
      }else{
        setLoading(true);
        if (id) {
          updateFileProperty(id, data)
            .then((res) => {
              setmsg({ err: false, msg: "Updated Successfully" });
              onSuccess();
            })
            .catch((err) => setmsg({ err: true, msg: err }))
            .finally(() => setLoading(false));
        }
      }
      
    }
  }

  return (
    <Modal
      id="UpdateFileModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        File Edit
      </ModalHeader>

      {!msg.err && msg.msg ? <Alert color="success">{msg.msg}</Alert> : null}

      {msg.err && msg.msg ? <Error error={msg.msg} /> : null}

      <ModalBody>
        <Form>
          <Row>

            <div className="form-group px-3 mb-2">
              {edited ? (
                <FileLinkViewer file={edited} module={"privateFile"} />
              ) : null}
            </div>

            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            

            <div className="form-group my-2">{selectTagsDropdown}</div>

            <div className="d-flex align-items-start gap-3">
              <button
                type="button"
                className="btn btn-primary add-btn my-2"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
                id="create-btn"
                style={{ width: "100%", backgroundColor: "#0AB39C" }}
                onClick={submit}
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Edit File
              </button>
            </div>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateFileModal;
