import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  updateImage,
  getSpaceSelectList,
  getInventoryTypes,
  updateInventory,
  retrieveInventory,
} from "../../../../helpers/backend_helper";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import { SpacesDropdown } from "../upload/spacesDropdown";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import { get_spaces } from "../../../../Components/utils/spacesCompare";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import {
  setSelectedFiles as setSelectedImageFiles,
  setSelectedSaveOld,
} from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";

const UpdateInvModal = ({ edited, toggle, onSuccess = () => {} }) => {
  const id = edited ? edited.id : null;
  // const fileName = edited ? "/" + edited.image.split("/").splice(-1) : null;

  const [ImageModal, ImageViewerComponent, getImageVal] = useImageSelect(
    false,
    "Inventory File",
    false
  );
  const [StatusSelectComponent, getStatusVal, setStatus] = useStatusSelect();

  const [msg, setmsg] = useState({ err: false, msg: null });
  const [loading, setLoading] = useState(false);

  const [invTypes, setinvTypes] = useState([]);

  const [type, setType] = useState(null);

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [selectedFile, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [notes, setnotes] = useState("");

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(res)
      );
    }
  }, [selectedProperty]);

  useEffect(() => {
    getInventoryTypes().then((res) => setinvTypes(res));
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (edited) {
      setName(edited.name);
      setSelectedSpaces(
        edited.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setType(edited.inventorytype);
      setnotes(edited.notes || "");
      setStatus({label:edited.status,value:edited.status});
      if (edited.image) {
        dispatch(setSelectedSaveOld([edited.image]));
      } else {
        dispatch(setSelectedSaveOld([]));
      }
    }
    setmsg({ err: false, msg: null });
    setFile(null);
  }, [edited]);

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("notes", notes);

    const image = getImageVal();
    if (image.length > 0) {
      if (!edited.image || image[0] !== edited.image.id) {
        formdata.append("image", image[0]);
      }
    } else {
      formdata.append("image", "");
    }

    const spaces = get_spaces(edited.spaces, selectedSpaces);
    if (spaces) {
      formdata.append("spaces", spaces.join(","));
    }

    formdata.append("inventorytype", type);

    const statusVal = getStatusVal();
    if (statusVal && statusVal !== edited.status ){
      formdata.append("status",statusVal)  
    }

    return formdata;
  }

  function submit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Image ");
    checkOrError(type, errs, "you need to add a name for your Image ");

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = getFormdata();
      setLoading(true);
      if (id) {
        updateInventory(id, data)
          .then((res) => {
            setmsg({ err: false, msg: "Updated Successfully" });
            onSuccess();
          })
          .catch((err) => setmsg({ err: true, msg: err }))
          .finally(() => setLoading(false));
      }
    }
  }

  return (
    <Modal
      id="UpdateInvModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Inventory Item Edit
      </ModalHeader>

      {!msg.err && msg.msg ? <Alert color="success">{msg.msg}</Alert> : null}

      {msg.err && msg.msg ? <Error error={msg.msg} /> : null}

      <ModalBody>
        <Form>
          {ImageModal}
          <Row>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            {/* {edited && edited.image ? (
            <div className="form-group my-2">
              <PrivateFileWraper id={edited.image} module={"inventoryPhoto"} ViewComponent={FileLinkViewer} />
            </div>):null }

            <div className="form-group my-2">
              <div
                className="input-group"
                style={{ padding: 0, margin: 0, marginRight: 0 }}
              >
                <label
                  for="updatefiles"
                  style={{
                    backgroundColor: "#299CDB",
                    width: "100%",
                  }}
                  class="btn btn-txt m-0"
                >
                  {selectedFile
                    ? selectedFile.name
                    : "Upload Image"}
                </label>
                <input
                  id="updatefiles"
                  style={{
                    display: "None",
                    backgroundColor: "#299CDB",
                  }}
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div> */}
            <div className="form-group mt-2">{ImageViewerComponent}</div>
            <div className="form-group my-2">{StatusSelectComponent}</div>

            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <select
                  className="form-select"
                  id="floatingSelect2"
                  aria-label="Floating label select example"
                  // // style={{ backgroundColor: "#eff2f7" }}
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="" disabled selected>
                    Inventory Type
                  </option>
                  {invTypes.map((type) => (
                    <option value={type.name}>{type.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="my-2">
              <SpacesDropdown
                stateChange={setSelectedSpaces}
                allOptions={SpaceSelectList}
                value={selectedSpaces}
              />
            </div>

            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  id="notes"
                  name="notes"
                  placeholder="Notes/Comments"
                  value={notes}
                  onChange={(e) => setnotes(e.target.value)}
                />
              </div>
            </div>

            <div className="d-flex align-items-start gap-3">
              <button
                type="button"
                className="btn btn-primary add-btn my-2"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
                id="create-btn"
                style={{ width: "100%", backgroundColor: "#0AB39C" }}
                onClick={submit}
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Edit Inventory Item
              </button>
            </div>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export function UpdateInvWraper({ edited, toggle, onSuccess = () => {} }) {
  const [inst, setInst] = useState(null);

  useEffect(() => {
    if (edited) {
      retrieveInventory(edited.id).then((res) => setInst(res));
    } else {
      setInst(null);
    }
  }, [edited]);

  return (
    <UpdateInvModal
      edited={edited ? inst : null}
      toggle={toggle}
      onSuccess={onSuccess}
    />
  );
}

export default UpdateInvModal;
