// import cities from "../../../assets/data/cities.json";
import React, { useEffect, useState } from "react";
import { UseCustomSelect } from "../../../Components/utils/select";
import { Label } from "reactstrap";

export const useAddress = ({ cities }) => {
  const countries = Object.keys(cities);
  const hasState = !(cities[countries[0]] instanceof Array);
  const [selectedPlace, setSelectedPlace] = useState({
    country: countries[0],
    state: hasState ? "" : "",
    hasState: hasState,
    cityIdx: -1,
  });

  function getCitiesdata() {
    return selectedPlace.hasState
      ? cities[selectedPlace.country][selectedPlace.state] instanceof Array
        ? cities[selectedPlace.country][selectedPlace.state]
        : null
      : cities[selectedPlace.country] instanceof Array
      ? cities[selectedPlace.country]
      : null;
  }

  function getState() {
    if (!selectedPlace.state) {
      throw "You need to select a State and a City";
    } else if (selectedPlace.cityIdx === -1) {
      throw "You need to select a city";
    }
    return {
      country: selectedPlace.country,
      state: selectedPlace.state,
      city: selectedPlace.hasState
        ? cities[selectedPlace.country][selectedPlace.state][
            selectedPlace.cityIdx
          ]
        : cities[selectedPlace.country][selectedPlace.cityIdx],
      hasState: selectedPlace.hasState,
    };
  }

  function getCurrentState() {
    return selectedPlace;
  }

  function setState(obj) {
    if (obj.country && obj.stateName && obj.cityName) {
      const idx = cities[obj.country][obj.stateName].findIndex(
        (object) => object.cityName === obj.cityName
      );
      setSelectedPlace({
        country: obj.country,
        state: obj.stateName,
        hasState: true,
        cityIdx: idx,
      });
    }
  }

  function ChangeCountry(country) {
    const hasState = !(cities[country] instanceof Array);
    // console.log()
    setSelectedPlace({
      country: country,
      state: "",
      hasState: hasState,
      cityIdx: -1,
    });
  }

  function ChangeCity(cityIdx) {
    setSelectedPlace((oldPlace) => ({ ...oldPlace, cityIdx: cityIdx }));
  }

  function ChangeState(state) {
    // state
    //     ? cities[oldPlace["country"]][state][0]
    //     : cities[oldPlace["country"]][0]
    setSelectedPlace((oldPlace) => ({
      ...oldPlace,
      state: state,
      cityIdx: -1,
      hasState: state ? true : false,
    }));
  }

  useEffect(() => {
    // console.log(selectedPlace , hasState)
    ChangeCountry("United States");
  }, []);

  return [
    <>
      {/* <div className="mb-3">
        <Label htmlFor="countries-use-input" className="form-label">
          Country
        </Label>
        <select
          className="form-select"
          data-choices
          data-choices-search-false
          id="countries-use-input"
          value={selectedPlace.country}
          onChange={(e) => ChangeCountry(e.target.value)}
          disabled
        >
          {countries?.map((country, idx) => (
            <option key={idx} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div> */}

      {selectedPlace.hasState ? (
        <div className="mb-3">
          <Label htmlFor="states-type-input" className="form-label">
            State
          </Label>
          <select
            className="form-select"
            data-choices
            data-choices-search-false
            id="states-type-input"
            value={selectedPlace.state}
            onChange={(e) => ChangeState(e.target.value)}
          >
            <option disabled value={""}>
              Select State
            </option>
            {Object.keys(cities[selectedPlace.country])?.map((state, idx) => (
              <option key={idx} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      <div className="mb-3">
        <Label htmlFor="cities-type-input" className="form-label">
          City
        </Label>
        <select
          className="form-select"
          data-choices
          data-choices-search-false
          id="cities-Select"
          value={selectedPlace.cityIdx}
          onChange={(e) => ChangeCity(e.target.value)}
        >
          <option disabled value={-1}>
            Select City
          </option>
          {getCitiesdata()?.map((inst, idx) => (
            <option key={idx} value={idx}>
              {inst.cityName}
            </option>
          ))}
        </select>
      </div>
    </>,
    getState,
    setState,
    getCurrentState,
  ];
};
