import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  // CardBody,
  // CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  getFloorFileTypes,
  getFloorTypes,
  updateFloor,
} from "../../../../helpers/backend_helper";
import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { FileLinkViewer, PrivateFileWraper } from "../../../../Components/Common/privateImageViewer/privateImageViewer";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles, setSelectedSaveOld } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

const UpdateFloorModal = ({ edited, toggle, onSuccess = () => {} }) => {
  const id = edited ? edited.id : null;

  const [fileTypes, setFileTypes] = useState([]);
  const [floorTypes, setFloorTypes] = useState([]);

  const [msg, setmsg] = useState({ err: false, msg: null });
  const [loading, setLoading] = useState(false);

  const [selectedFile, setFile] = useState([]);
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Floorplan File",false)
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [notes, setnotes] = useState("");

  const dispatch = useDispatch()

  useEffect(() => {
    if (edited) {
      setName(edited.name);
      setType(edited.levelType);
      // setFileType(edited.fileType)
      setnotes(edited.notes || "");
      if(edited.levelImg){
        dispatch(setSelectedSaveOld([edited.levelImg]))
      }else{
        dispatch(setSelectedSaveOld([]))
      }
    }
    setmsg({ err: false, msg: null });
    setFile([])
  }, [edited]);

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    // formdata.append("fileType", fileType);
    // if (selectedFile.length > 0) {
    //   formdata.append("image", selectedFile[0].file);
    // }

    const image = getImageVal()
    if (image.length > 0 ){
      if(!edited.image || image[0] !== edited.image.id){
        formdata.append("image", image[0]);
      }
    }else{
      formdata.append("image", "");
    }

    formdata.append("levelType", type);
    formdata.append("notes", notes);
    return formdata;
  }

  useEffect(() => {
    getFloorTypes().then((res) => setFloorTypes(res));
  }, []);

  useEffect(() => {
    getFloorFileTypes().then((res) =>
      setFileTypes(res.map((type) => type.name))
    );
  }, []);

  function submit() {
    var errs = [];

    checkOrError(name, errs, "you need to select a name for your floor plan");

    // checkOrError(fileType, errs, "you need to select floor plan file type");

    checkOrError(type, errs, "you need to select floor plan level");

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = getFormdata();
      setLoading(true);
      if (id) {
        updateFloor(id, data)
          .then((res) => {
            setmsg({ err: false, msg: "Updated Successfully" });
            onSuccess();
          })
          .catch((err) => setmsg({ err: true, msg: err }))
          .finally(() => setLoading(false));
      }
    }
  }

  useEffect(() => {
    if (selectedFile.length > 0) {
      const ext = selectedFile[0].file.name.split(".").pop().toUpperCase();
      const exists = fileTypes.includes(ext);
      if (!exists) {
        const allowedExt = fileTypes.join(",");
        setFile(null);
        setFileType(edited ? edited.fileType : "");
        setmsg({
          err: true,
          msg: `.${ext} files aren't supported for floor file field, you need to provide a file in one of these extensions ${allowedExt}`,
        });
      } else {
        setFileType(ext);
      }
    }
  }, [selectedFile]);

  return (
    <Modal
      id="updateFloorModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Floorplan Edit
      </ModalHeader>

      {!msg.err && msg.msg ? <Alert color="success">{msg.msg}</Alert> : null}

      {msg.err && msg.msg ? <Error error={msg.msg} /> : null}

      <ModalBody>
        {ImageModal}
        <Form action="#">
          <Row>
            <div className="mb-3">
              <div className="form-group my-2">
                <div className="input-group" style={{ padding: 0 }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    aria-label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* <div className="mb-3">
              <div className="form-group my-2">
                <div className="input-group" style={{ padding: 0 }}>
                  <select
                    className="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    // style={{ backgroundColor: "#eff2f7" }}
                    onChange={(e) => setFileType(e.target.value)}
                    value={fileType}
                    disabled
                  >
                    <option value="" disabled selected>
                      Floor Plan File Type
                    </option>
                    {fileTypes.map((type) => (
                      <option value={type}>{type}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div> */}
            {/* {edited && edited.levelImg ? (
              <div className="mb-3">
                <PrivateFileWraper id={edited.levelImg} module={"levelPhoto"} ViewComponent={FileLinkViewer} />
              </div>
            ) : null} */}

            <div className="mb-3">
              {/* <FilePond
                files={selectedFile}
                onupdatefiles={setFile}
                allowMultiple={true}
                maxFiles={1}
                // acceptedFileTypes={['image/png', 'image/jpeg']}
                labelIdle={
                  "Upload Floorplan (SVG, PNG, JPG, PDF): Drag here or Click to browse"
                }
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {ImageViewerComponent}
            </div>

            <div className="mb-3">
              <div className="input-group" style={{ padding: 0 }}>
                <select
                  className="form-select"
                  id="floatingSelect2"
                  aria-label="Floating label select example"
                  // style={{ backgroundColor: "#eff2f7" }}
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option value="" disabled selected>
                    Level Type
                  </option>
                  {floorTypes.map((type) => (
                    <option value={type.name}>{type.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group my-2">
                <div className="input-group" style={{ padding: 0 }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Notes/Comments"
                    aria-label="Name"
                    value={notes}
                    onChange={(e) => setnotes(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Form>

        <div className="d-flex align-items-start gap-3">
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={submit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Edit Level
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateFloorModal;
