import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  FormFeedback,
  Input,
  Button,
  Alert,
  Spinner,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";

import AuthSlider from "../authCarousel";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { registerUser, apiError, resetRegisterFlag } from "../../slices/thunks";

import {
  registerUser,
  apiError,
  resetRegisterFlag,
} from "../../../slices/auth/register/thunk";
import Select from "react-select";
import { getRoles } from "../../../helpers/backend_helper";
import { setSignUp2 } from "../../../slices/auth/register/reducer";

export const SignUpForm2 = ({ goBack }) => {
  // const [roles, setRoles] = useState([]);

  const dispatch = useDispatch();

  const { error, success, formikState, loading, signUp1Data } = useSelector(
    (state) => ({
      success: state.Account.success,
      error: state.Account.error,
      formikState: state.Account.signup2,
      loading: state.Account.loading,
      signUp1Data: state.Account.signup1,
    })
  );

  // const [role,setRole] = useState(formikState.role)

  // useEffect(()=>{
  //     if(roles.length === 0){
  //         getRoles().then((res)=> {
  //             console.log(res)
  //             setRoles(res.map((role)=> {return {value:role.role_name,label:role.role_name}}))
  //         })
  //     }
  // },[])

  // useEffect(()=>{
  //     if(roles.length > 0){
  //         if (!role){
  //             setRole(roles[0])
  //         }
  //     }
  // },[roles])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: formikState,
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      contactNumber: Yup.string().required("This field is required"),
      address: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
      zipCode: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      // dispatch(registerUser(values.email,values.name,values.password))
      // dispatch(setSignUp2(values))
      const data = { ...signUp1Data, ...values, role: formikState.role };
      // console.log(data)
      dispatch(registerUser(data));
    },
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFFFFF",
    }),
  };

  return (
    <div className="mt-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit(e);
        }}
        className="needs-validation"
        noValidate
        action="index"
      >
        <div className="mb-3">
          <label htmlFor="First Name" className="form-label">
            First Name <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="firstName-input"
            placeholder="First Name"
            name="firstName"
            value={validation.values.firstName}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.firstName && validation.touched.firstName
                ? true
                : false
            }
          />

          {validation.errors.firstName && validation.touched.firstName ? (
            <FormFeedback type="invalid">
              {validation.errors.firstName}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="Last Name" className="form-label">
            Last Name <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="lastName-input"
            placeholder="Last Name"
            name="lastName"
            value={validation.values.lastName}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.lastName && validation.touched.lastName
                ? true
                : false
            }
          />

          {validation.errors.lastName && validation.touched.lastName ? (
            <FormFeedback type="invalid">
              {validation.errors.lastName}
            </FormFeedback>
          ) : null}
        </div>

        {/* <div className="mb-3">
                <label htmlFor="Last Name" className="form-label">Role <span className="text-danger">*</span></label>
    
                <Select styles={customStyles} value={role} onChange={(val)=> setRole(val)}  placeholder={"Account Type"} options={roles} />
                
                {validation.errors.lastName && validation.touched.lastName ? (
                    <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                ) : null}
            
            </div> */}

        <div className="mb-3">
          <label htmlFor="Contact Number" className="form-label">
            Contact Number <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="contactNumber-input"
            placeholder="Contact Number"
            name="contactNumber"
            value={validation.values.contactNumber}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.contactNumber &&
              validation.touched.contactNumber
                ? true
                : false
            }
          />

          {validation.errors.contactNumber &&
          validation.touched.contactNumber ? (
            <FormFeedback type="invalid">
              {validation.errors.contactNumber}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="Address" className="form-label">
            Address <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="address-input"
            placeholder="Address"
            name="address"
            value={validation.values.address}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.address && validation.touched.address
                ? true
                : false
            }
          />

          {validation.errors.address && validation.touched.address ? (
            <FormFeedback type="invalid">
              {validation.errors.address}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="City" className="form-label">
            City <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="city-input"
            placeholder="city"
            name="city"
            value={validation.values.city}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.city && validation.touched.city ? true : false
            }
          />

          {validation.errors.city && validation.touched.city ? (
            <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="State" className="form-label">
            State <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="state-input"
            placeholder="state"
            name="state"
            value={validation.values.state}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.state && validation.touched.state ? true : false
            }
          />

          {validation.errors.state && validation.touched.state ? (
            <FormFeedback type="invalid">
              {validation.errors.state}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="Country" className="form-label">
            Country <span className="text-danger">*</span>
          </label>

          <Input
            disabled
            type="text"
            className="form-control"
            id="country-input"
            placeholder="Country"
            name="country"
            value={validation.values.country}
            onBlur={validation.handleBlur}
            invalid={
              validation.errors.country && validation.touched.country
                ? true
                : false
            }
          />

          {validation.errors.country && validation.touched.country ? (
            <FormFeedback type="invalid">
              {validation.errors.country}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <label htmlFor="zipCode" className="form-label">
            Zip Code <span className="text-danger">*</span>
          </label>

          <Input
            type="text"
            className="form-control"
            id="zipCode-input"
            placeholder="Zip Code"
            name="zipCode"
            value={validation.values.zipCode}
            onBlur={validation.handleBlur}
            onChange={validation.handleChange}
            invalid={
              validation.errors.zipCode && validation.touched.zipCode
                ? true
                : false
            }
          />

          {validation.errors.zipCode && validation.touched.zipCode ? (
            <FormFeedback type="invalid">
              {validation.errors.zipCode}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-4">
          <p className="mb-0 fs-12 text-muted fst-italic">
            By registering you agree to the DwellDoc{" "}
            <Link
              to="#"
              className="text-primary text-decoration-underline fst-normal fw-medium"
            >
              Terms of Use
            </Link>
          </p>
        </div>

        <p
          onClick={() => {
            dispatch(setSignUp2(validation.values));
            goBack();
          }}
          style={{ cursor: "pointer" }}
          className="fw-semibold text-primary text-decoration-underline"
        >
          goBack
        </p>

        <div className="mt-4">
          <Button
            disabled={success || error || loading ? true : false}
            color="success"
            className="w-100"
            type="submit"
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                Loading...{" "}
              </Spinner>
            ) : null}
            Sign Up
          </Button>
        </div>

        {/* <div className="mt-4 text-center">
                <div className="signin-other-title">
                    <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                </div>

                <div>
                    <button type="button" className="btn btn-primary btn-icon waves-effect waves-light me-1"><i className="ri-facebook-fill fs-16"></i></button>
                    <button type="button" className="btn btn-danger btn-icon waves-effect waves-light me-1"><i className="ri-google-fill fs-16"></i></button>
                    <button type="button" className="btn btn-dark btn-icon waves-effect waves-light me-1"><i className="ri-github-fill fs-16"></i></button>
                    <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                </div>
            </div> */}
      </form>
    </div>
  );
};
