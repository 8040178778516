import { useEffect, useState } from "react";
import { Col, Input, Label, Progress, Row } from "reactstrap";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  deleteAsset,
  set_delete_id,
  submit_instance,
  submit_next,
  thumbnailClick,
  updateAsset,
} from "../../../slices/contractor/createPortfolio/reducer";
import { useDispatch, useSelector } from "react-redux";
import { FilePond } from "react-filepond";

import swapImage from "../../../assets/images/icons/swap.png";
import {
  getVimeoId,
  getYoutubeId,
} from "../../../Components/utils/get_video_id";

import cancelImage from "../../../assets/images/Cross_red_circle.png";
import { useProgressBarWithLabelAndErrorNotifier } from "../../../Components/Common/progressBar/progressBarWithLabel";
import config from "../../../config";
import { max_file_size_project } from "../../../Components/constants/constants";
import {
  API_CreateAsset,
  API_UpdateAsset,
} from "../../../helpers/backend_helper";
import { filter_object_list_keys } from "../../../Components/utils/object_manipulation";
import { CreateAsset } from "../../../helpers/url_helper";
import { toast } from "react-toastify";
import { toastError } from "../../../Components/error";
import { Link } from "react-router-dom";

export function TypeCheckBox({ val, onChange, disabled }) {
  return (
    <div className="my-2 input-light">
      <select
        className="form-control"
        data-choices
        data-choices-search-false
        name="choices-single-default"
        value={val}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option value={"Video"}>Video</option>
        <option value={"Image"}>Image</option>
      </select>
    </div>
  );
}

export function VideoTypeCheckBox({ val, onChange, disabled }) {
  return (
    <div className="input-light">
      <select
        className="form-control"
        data-choices
        data-choices-search-false
        name="choices-single-default"
        value={val}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option value={"Youtube"}>Youtube</option>
        <option value={"Vimeo"}>Vimeo</option>
      </select>
    </div>
  );
}

export const VideoComponent = ({
  videoType,
  onChangeVideoType,
  videoId,
  onChangeVideoId,
  disabled,
}) => {
  // const [videoType, setVideoType] = useState("Youtube");

  const [videoLink, setVideoLink] = useState("");
  // const [videoId, setVideoId] = useState(null);

  return (
    <>
      <VideoTypeCheckBox
        disabled={disabled}
        val={videoType}
        onChange={onChangeVideoType}
      />
      <div className="asset-box">
        {videoId ? (
          videoType === "Youtube" ? (
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              className=""
              frameborder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              src={`https://player.vimeo.com/video/${videoId}`}
              frameborder="0"
              allowFullScreen
            >
              <img
                src={`https://i.vimeocdn.com/video/${videoId}_00001.jpg`}
                alt="Vimeo video thumbnail"
                class="thumbnail"
              />
            </iframe>
          )
        ) : null}
      </div>
      <div className="p-2">
        <div className="input-group">
          <Input
            type="text"
            className="form-control"
            aria-label="Video Link"
            aria-describedby="button-addon2"
            placeholder="Enter Your Video Link"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            disabled={disabled}
          />
          <button
            className="btn btn-outline-success"
            type="button"
            // id="button-addon2"
            disabled={disabled}
            onClick={() => {
              var id =
                videoType === "Youtube"
                  ? getYoutubeId(videoLink)
                  : videoType === "Vimeo"
                  ? getVimeoId(videoLink)
                  : null;
              onChangeVideoId(id);
            }}
          >
            View
          </button>
        </div>
      </div>
    </>
  );
};

const Handle = SortableHandle(({ tabIndex }) => (
  <div className="handle-wraper" tabIndex={tabIndex}>
    <img src={swapImage} width="30" height="30" />
  </div>
));

export const AssetInputComponent = ({ item, index }) => {
  const dispatch = useDispatch();

  const thumbnail = useSelector((state) => state.portfolioCreate.thumbnailId);
  const submitId = useSelector((state) => state.portfolioCreate.submitId);
  const project = useSelector((state) => state.portfolioCreate.project);
  const user = useSelector((state) => state.Login.user);
  const formState = useSelector((state) => state.portfolioCreate.state);

  const [file, setFile] = useState([]);

  const [
    ProgressBarComponent,
    setProgress,
    onSuccessFileProgress,
    onFailure,
    resetProgressBar,
  ] = useProgressBarWithLabelAndErrorNotifier();

  const onChangeData = (key, val) => {
    const newItem = { ...item };
    newItem[key] = val;
    if ((key === "type") & (val === "Video")) {
      newItem["size"] = 0;
    }
    dispatch(updateAsset(newItem));
  };

  const onUploadFinish = (data) => {
    dispatch(submit_next(data));
  };

  function uploadFile(asset) {
    var formData = new FormData();
    formData.append("image", file[0].file);

    const newData = filter_object_list_keys(asset, [
      "title",
      "isThumbnail",
      "order",
    ]);

    for (const key in newData) {
      formData.append(key, newData[key]);
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", config.api.API_URL + CreateAsset(project.id), true);
    xhr.setRequestHeader("Authorization", "Token " + user.token);

    xhr.upload.addEventListener("loadstart", () => {
      setProgress(0);
    });

    xhr.upload.addEventListener("progress", function (e) {
      if (e.lengthComputable) {
        var percent = Math.round((e.loaded / e.total) * 100);
        setProgress(percent);
      }
    });

    xhr.onerror = function (err) {
      onFailure(err);
      onUploadFinish({ ...asset, created: false });
    };

    xhr.onload = function () {
      var data = null;
      if (this.status === 201) {
        onSuccessFileProgress();
        data = JSON.parse(this.responseText);
        data = {
          ...data,
          real_id: data.id,
          id: asset.id,
          created: true,
          type: asset.type,
        };
      } else if (this.status === 413) {
        onFailure({
          "file size": `File is too large, please try again with smaller file ( max upload size is ${max_file_size_project} )`,
        });
        data = { ...asset, created: false };
      } else {
        const errmsg = JSON.parse(this.responseText);
        onFailure(errmsg);
        data = { ...asset, created: false };
      }
      onUploadFinish(data);
    };
    // console.log(formData)
    xhr.send(formData);
  }

  useEffect(() => {
    if (file.length > 0) {
      onChangeData("size", file[0].fileSize);
    } else {
      onChangeData("size", 0);
    }
  }, [file]);


  const createAssetFunction = (asset) => {
    resetProgressBar();
    if (asset.type === "Image") {
      if (file.length > 0) {
        uploadFile(asset);
      } else {
        onFailure({ image: "no Image Provided" });
        var data = { ...asset, created: false };
        onUploadFinish(data);
      }
    } else if (asset.type === "Video") {
      const newData = filter_object_list_keys(asset, [
        "title",
        "video_type",
        "video_id",
        "isThumbnail",
        "order",
      ]);
      API_CreateAsset(project.id, newData)
        .then((res) => {
          onUploadFinish({
            ...res,
            real_id: res.id,
            id: asset.id,
            created: true,
            type: asset.type,
          });
          onSuccessFileProgress();
        })
        .catch((err) => {
          onFailure(err);
          onUploadFinish({ ...asset, created: false });
        });
    } else {
      onFailure("Your asset must have a type either video or image");
      onUploadFinish({ ...asset, created: false });
    }
  };

  useEffect(() => {
    // console.log(submitId, item);
    if (submitId && submitId.id === item.id) {
      createAssetFunction(submitId);
    }
  }, [submitId]);

  const getUpdateData = () => {
    var data = {};
    data["title"] = item.title;
    if (thumbnail === item.id) {
      data["isThumbnail"] = true;
    }
    data["order"] = index;
    return data
  };

  const onBtnClick = () => {
    if (formState !== "create") {
      if (item.created) {
        resetProgressBar();
        API_UpdateAsset(item.real_id, getUpdateData())
          .then((res) => {
            toast.success("Updated Successfully");
            onSuccessFileProgress();
          })
          .catch((err) => onFailure(err));
      } else {
        createAssetFunction({
          ...item,
          order: index,
          isThumbnail: thumbnail === item.id,
        });
      }
    }
  };

  return (
    <Col className="asset-container" xl={5}>
      <Row>
        <Col xs={10}>
          {(formState === "create" || formState === "reorder") && <Handle />}
        </Col>
        <Col xs={2}>
          <div className="hstack gap-2 justify-content-end pt-2">
            {formState === "create" || formState === "update" ?  
            item.created ? (
              <span
                to="#"
                style={{ cursor: "pointer" }}
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  dispatch(set_delete_id(item.real_id));
                }}
              >
                <i className="ri-delete-bin-5-fill"></i>
              </span>
            ) : (
              <img
                src={cancelImage}
                onClick={() => dispatch(deleteAsset(item.id))}
                style={{ cursor: "pointer" }}
                width="30"
                height="30"
              />
            ):null}
          </div>
        </Col>
      </Row>
      <Row>
        <TypeCheckBox
          disabled={item.created}
          val={item.type}
          onChange={(val) => onChangeData("type", val)}
        />
        {item.type === "Video" ? (
          <VideoComponent
            disabled={item.created}
            videoType={item.video_type}
            onChangeVideoType={(val) => onChangeData("video_type", val)}
            onChangeVideoId={(val) => onChangeData("video_id", val)}
            videoId={item.video_id}
          />
        ) : (
          <div className="asset-box">
            {item.created ? (
              <img
                style={{ width: "100%", height: "100%" }}
                src={item.image}
                alt=""
              />
            ) : (
              <FilePond
                files={file}
                onupdatefiles={setFile}
                allowMultiple={true}
                maxFiles={1}
                height="200px"
                acceptedFileTypes={["image/*"]}
                labelIdle={"Select Your File: Drag here or Click to browse"}
                name="files"
                className="filepond filepond-input-multiple"
                imagePreviewHeight={"200px"}
                // imagePreviewM
              />
            )}
          </div>
        )}
        <div className="px-2">
          <Input
            type="text"
            className="form-control"
            aria-label="Title (optional)"
            aria-describedby="button-addon2"
            placeholder="Title (optional)"
            value={item.title}
            onChange={(e) => onChangeData("title", e.target.value)}
          />
        </div>
        <Row className="p-2">
          <Col lg={6}>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                checked={thumbnail === item.id}
                onClick={() => dispatch(thumbnailClick(item.id))}
              />
              <Label
                // onClick={() => dispatch(thumbnailClick(item.id))}
                className="form-check-label"
                htmlFor="flexRadioDefault1"
              >
                Thumbnail
              </Label>
            </div>
          </Col>
          <Col lg={6}>
            <Row>
              <Col xs={8}>{ProgressBarComponent}</Col>
              {formState !== "create" ? (
                <Col class xs={4}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      onClick={onBtnClick}
                      className="btn btn-sm btn-success"
                    >
                      {item.created ? "update" : "create"}{" "}
                    </button>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};

export const SortableAsset = SortableElement((sortableProps) => {
  const { value: item, idx: index } = sortableProps;
  return <AssetInputComponent index={index} item={item} />;
});

export const SortableList = SortableContainer((props) => {
  const { items, ...restProps } = props;
  return (
    <Row>
      {items.map((item, index) => (
        <SortableAsset
          key={`item-${item.id}`}
          index={index}
          idx={index}
          value={item}
          {...restProps}
        />
      ))}
    </Row>
  );
});
