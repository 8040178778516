import React, { useState } from "react";
import { Card, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_delete_id } from "../../../slices/contractor/createPortfolio/reducer";
import {
  set_delete_portfolio,
  set_selected_portfolio_id,
} from "../../../slices/contractor/portfolioList/reducer";

export const PortfolioItem = ({ item, edit }) => {
  // img, title
  // console.log(item);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const thumbnail = item.thumbnail;
  return (
    <Col
      xxl={3}
      xl={4}
      md={4}
      sm={6}
      className="element-item project designing development"
    >
      <Card
        className="gallery-box profile-portfolio"
        onClick={() => {
          // console.log("clicked");
          navigate("?project_id=" + item.id);
          // dispatch(set_selected_portfolio_id(item.id));
        }}
      >
        <div className="gallery-container">
          <div className="image-popup" to="#" title="">
            {thumbnail.image ? (
              <img
                className="gallery-img img-fluid mx-auto"
                src={thumbnail.image}
                alt=""
              />
            ) : thumbnail.video_type === "Vimeo" ? (
              <iframe
                src={`https://player.vimeo.com/video/${thumbnail.video_id}`}
                frameborder="0"
                allowfullscreen
              >
                <img
                  src={`https://i.vimeocdn.com/video/${thumbnail.video_id}_00001.jpg`}
                  alt="Vimeo video thumbnail"
                  class="thumbnail img-fluid mx-auto"
                />
              </iframe>
            ) : thumbnail.video_type === "Youtube" ? (
              <iframe
                src={`https://www.youtube.com/embed/${thumbnail.video_id}`}
                frameborder="0"
                allowfullscreen
              ></iframe>
            ) : (
              <div
                className="gallery-img img-fluid mx-auto"
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "150px",
                  backgroundColor: "black",
                }}
              />
            )}
            <div
              // to={}
              className="d-flex flex-column gallery-overlay profile-project"
            >
              {edit ? (
                <div className="d-flex align-items-center m-auto flex-grow-1 justify-content-center">
                  <button
                    className="btn-info rounded-pill shadow-lg btn btn-icon btn-md btn-light p-1 m-1 text-center"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/portfolio/project/${item.id}/update`);
                    }}
                    style={{ position: "relative", zIndex: 4 }}
                  >
                    {" "}
                    <i
                      className="ri-pencil-line fs-17 center-abs-before"
                      style={{ margin: "auto" }}
                    />{" "}
                  </button>
                  <button
                    style={{ position: "relative", zIndex: 4 }}
                    className="btn-info rounded-pill shadow-lg btn btn-icon btn-md btn-light p-1 m-1"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(set_delete_portfolio(item.id));
                    }}
                  >
                    <i
                      className="ri-delete-bin-5-line fs-17 center-abs-before"
                      style={{ margin: "auto" }}
                    />{" "}
                    {/* <img src={swapImage} className="up-down" />{" "} */}
                  </button>
                </div>
              ) : null}

              <h5 className="overlay-caption">{item.title}</h5>
            </div>
          </div>
        </div>
        <Link to="#" className="text-primary fw-medium normal">
          {item.title}
        </Link>
      </Card>
    </Col>
  );
};
