export const CardSelectComp = ({ img, title, clicked = true , onClick = () => {} }) => {
  var className = clicked ? " clicked" : "";
  return (
    <div onClick={onClick} class="card-wrapper-select">
      <div class={"card-select" + className}>
        <div class="card-image">
          <img src={img} alt={title} />
        </div>

        <div class="details details-txt">
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  );
};
