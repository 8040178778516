import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap";
import { useSelector , useDispatch } from "react-redux";

import ImageOrLoad from "./ImageOrLoad";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";

// import { useSelector } from "react-redux";
import { getProfileThunk } from "../../slices/auth/profile/thunk";
import { logoutUser } from "../../slices/thunks";

const ProfileDropdown = () => {


  const dispatch = useDispatch()

  const { userProfile, user , loading } = useSelector((state) => ({
    userProfile: state.Profile.user,
    user: state.Login.user,
    loading: state.Profile.loading,
  }));

  useEffect(() => {
    if (!userProfile) {
      dispatch( 
        getProfileThunk(user)
      );
    }
  }, []);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {loading ? (
              <Spinner />
            ) : (
              <ImageOrLoad
                className="rounded-circle header-profile-user"
                src={
                  userProfile.profile_picture
                    ? userProfile.profile_picture
                    : avatar1
                }
                alt="Header Avatar"
              />
            )}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">
              {user.role}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {user.name}!</h6>
          <DropdownItem className="p-0">
            <Link
              to={process.env.PUBLIC_URL + "/profile"}
              className="dropdown-item"
            >
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link
              onClick={()=> dispatch(logoutUser(user.token))} 
              to={process.env.PUBLIC_URL + "/logout"}
              className="dropdown-item"
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
