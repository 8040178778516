export function format_stay_place(stay_place) {
    if(stay_place) {
        if(stay_place.state){
            return stay_place.city + ', ' + stay_place.state + ', ' + stay_place.country
        }else{
            return stay_place.city + ', ' + stay_place.country
        }
    }else{
        return ""
    }
}

export function get_stay_place_update(address) {
    return {country: address.country, state: address.state , city: address.city.cityName}
}