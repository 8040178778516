export function moveElement(arr, oldIndex, newIndex) {
  if (
    oldIndex < 0 ||
    oldIndex >= arr.length ||
    newIndex < 0 ||
    newIndex >= arr.length
  ) {
    return arr; // Handle invalid indices
  }

  const elementToMove = arr.splice(oldIndex, 1)[0];
  arr.splice(newIndex, 0, elementToMove);
  return arr;
}

export function generateId() {
  return `${Date.now().toString(36)}-${Math.random()
    .toString(36)
    .substring(2, 5)}`;
}


export function compareArrays(arr1, arr2) {

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id != arr2[i].id) {
      return false; // Elements at the same index are not equal
    }
  }
  return true; // All elements match in the same order
}