import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFloorsSideNavThunk } from "../slices/sideNav/thunk";
import { toggleFloor } from "../slices/sideNav/reducer";
import { subscribe, unsubscribe } from "../events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// // font awesome icons explict import for smaller bundle size
import { faGauge } from "@fortawesome/free-solid-svg-icons/faGauge";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faWind } from "@fortawesome/free-solid-svg-icons/faWind";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faImages } from "@fortawesome/free-solid-svg-icons/faImages";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faCube } from "@fortawesome/free-solid-svg-icons/faCube";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons/faMapLocation";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons/faBookOpen";
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons/faDiagramProject";
import { faBarcode } from "@fortawesome/free-solid-svg-icons/faBarcode";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faHouseCrack } from "@fortawesome/free-solid-svg-icons/faHouseCrack"
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell"
// import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons/faPeopleGroup";
// import {faBarsProgress} from "@fortawesome/free-solid-svg-icons/faBarsProgress";

import { faListCheck } from "@fortawesome/free-solid-svg-icons/faListCheck";
import { faBarsProgress } from "@fortawesome/free-solid-svg-icons/faBarsProgress";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons/faPeopleGroup";
import { faHelmetSafety } from "@fortawesome/free-solid-svg-icons/faHelmetSafety";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";

import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faSquare } from "@fortawesome/free-solid-svg-icons/faSquare";
import { faHeadset } from "@fortawesome/free-solid-svg-icons/faHeadset";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons/faStickyNote";

import LocationIcon from "../assets/images/icons/Location.svg";
import CaptureIcon from "../assets/images/icons/3D Captures.svg";
import AppliancesIcon from "../assets/images/icons/Appliances.svg";
import FinishesIcon from "../assets/images/icons/Finishes_Alt.svg";
import FlooringIcon from "../assets/images/icons/Flooring.svg";
import HVACIcon from "../assets/images/icons/HVAC.svg";
import InventoryIcon from "../assets/images/icons/Inventory.svg";

import FloorplanIcon from "../assets/images/icons/Floorplan.svg";
import ImagesIcon from "../assets/images/icons/Images.svg";
import InsuranceIcon from "../assets/images/icons/Insurance.svg";
import MaintenanceIcon from "../assets/images/icons/Maintenance.svg";
import UtilitiesIcon from "../assets/images/icons/Utilities.svg";

import { ReactSVG } from 'react-svg'
import { GETPropertyElementsSettingsConfig } from "../helpers/url_helper";
import { getAllPropertyElementsSettingsConfig, getAllPropertyElementsSettingsTable } from "../helpers/backend_helper";


const Navdata = () => {

  const history = useNavigate();
  //config
  const [propertyElementsConfig, setpropertyElementsConfig] = useState({
    "Appliance": true,
    "Flooring": true,
    "Finishes": true,
    "HVAC": true,
    "Inventory": true,
    "Insurance": true,
    "Utilities": true
  });
  
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isProperty, setIsProperty] = useState(false);
  const [isImage, setisImage] = useState(false);
  const [isReport, setisReport] = useState(false);
  const [isInsurance, setisInsurance] = useState(false);
  const [isinventory, setisinventory] = useState(false);
  const [isutil, setisutil] = useState(false);
  const [isappliance, setisappliance] = useState(false);

  const [isStorage, setIsStorage] = useState(false);
  const [ispropertyElements, setIspropertyElements] = useState(false);
  const [ismanagement, setIsManagement] = useState(false);
  const [isSupport, setIsSupport] = useState(false);


  const [isProjectManager, setisProjectManager] = useState(false);
  const [isServiceProviders, setisServiceProviders] = useState(false);

  const [isProjects, setisProjects] = useState(false);
  const [isContractors, setisContractors] = useState(false);

  const [isMyContractors, setIsMyContractors] = useState(false);

  const [ispropertyElementsSettings, setispropertyElementsSettings] = useState(false);
  

  const { floors, floorsState, selectedProperty,elementSettingsRefresh , user } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    floors: state.sideNav.floors,
    floorsState: state.sideNav.floorsState,
    user: state.Login.user,
    elementSettingsRefresh: state.PropertiesDropdown.elementSettingsRefresh
  }));

  const dispatch = useDispatch();

  function onFloorClick(e, idx) {
    e.preventDefault();
    dispatch(toggleFloor(idx));
    updateIconSidebar(e);
  }

  function generateLevel(level, key) {
    return {
      id: key,
      label: level.name,
      link: "/level/" + level.id,
      stateVariables: floorsState[key],
      click: (e) => onFloorClick(e, key),
      childItems: level.spaces.map((space, idx) => ({
        id: idx,
        label: space.name,
        link: "/level/" + level.id,
        // link: "/spaces/" + space.id,
      })),
    };
  }

  const [isInfo, setIsInfo] = useState(false);
  const [isLevel, setIsLevel] = useState(false);

  function getFloors(selectedProperty) {
    if (selectedProperty) {
      dispatch(getFloorsSideNavThunk(selectedProperty.id));
    }
  }

  useEffect(() => {
    getFloors(selectedProperty);
  }, [selectedProperty]);

  useEffect(()=>{
    if (selectedProperty) {
      getAllPropertyElementsSettingsConfig(selectedProperty.id)
      .then((res) => setpropertyElementsConfig(res))
    }
  },[selectedProperty,elementSettingsRefresh])


  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "property") {
      setIsProperty(false);
    }
    if (iscurrentState !== "Information") {
      setIsInfo(false);
    }
    if (iscurrentState !== "projectManager") {
      setisProjectManager(false);
    }
    if (iscurrentState !== "serviceProvider") {
      setisServiceProviders(false);
    }
    if(iscurrentState !== "Management"){
      setIsManagement(false);
    }
    if(iscurrentState !== "Elements"){
      setIspropertyElements(false)
    }
    if(iscurrentState !== "Storage"){
      setIsStorage(false)
    }
    if(iscurrentState !== "Support"){
      setIsSupport(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isProperty,
    isInfo,
    isProjectManager,
    isServiceProviders,
    isMyContractors,
    isInsurance,
    ismanagement,
    isSupport,
    ispropertyElementsSettings
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },

    {
      id: "dashboard",
      label: "Dashboards",
      icon: (
        <FontAwesomeIcon
          icon={faGauge}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Property",
          label: "Property",
          icon: (
            <FontAwesomeIcon
              icon={faBuilding}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
          ),
          link: "/property/list/",
          parentId: "dashboard",
        },
        // {
        //   id: "projects",
        //   label: "Property",
        //   icon: (
        //     <FontAwesomeIcon
        //       icon={faDiagramProject}
        //       style={{ fontSize: "15px", color: "#ABB9E8" }}
        //     />
        //   ),
        //   link: "/projects",
        //   parentId: "dashboard",
        // },
      ],
    },
    {
      id: "information",
      label: "Property Workbook",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faBookOpen}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      link: "/#",
      stateVariables: isInfo,
      click: function (e) {
        e.preventDefault();
        setIsInfo(!isInfo);
        setIscurrentState("Information");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "location",
          label: "Location",
          link: "/location",
          icon: (
            // <FontAwesomeIcon
            //   icon={faMapLocation}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={LocationIcon} />
          ),
          parentId: "information",
        },
        {
          id: "3dCapture",
          label: "3D Capture(s)",
          link: "/3dCapture",
          icon: (
            // <FontAwesomeIcon
            //   icon={faCube}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={CaptureIcon} />
          ),
          parentId: "information",
        },
        {
          id: "levels",
          label: "Floorplan(s)",
          link: "/floorplans",
          isChildItem: true,
          stateVariables: isLevel,
          icon: (
            // <FontAwesomeIcon
            //   icon={faLayerGroup}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
             <ReactSVG className="left-nav-icons"
             src={FloorplanIcon} />
            
          ),
          parentId: "information",
          click: function (e) {
            e.preventDefault();
            setIsLevel(!isLevel);
            updateIconSidebar(e);
          },
          childItems: floors.map(generateLevel),
        }, 
        {
          id: "notes",
          label: "Notes",
          link: "/notes",
          icon: (
            <FontAwesomeIcon
              icon={faStickyNote}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
            // <ReactSVG className="left-nav-icons"
            //  src={CaptureIcon} />
          ),
          parentId: "information",
        },
        {
          id: "reminders",
          label: "Reminders",
          link: "/reminders",
          icon: (
            <FontAwesomeIcon
              icon={faBell}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
            // <ReactSVG className="left-nav-icons"
            //  src={CaptureIcon} />
          ),
          parentId: "information",
        },

        // {
        //   id: "MyContractors",
        //   label: "My Contractors",
        //   link: "/mycontractors",

        //   stateVariables: isMyContractors,
        //   icon: (
        //     <FontAwesomeIcon
        //       icon={faHelmetSafety}
        //       style={{ fontSize: "15px", color: "#ABB9E8" }}
        //     />
        //   ),
        //   parentId: "information",
        //   click: function (e) {
        //     e.preventDefault();
        //     setIsMyContractors(!isMyContractors);
        //     updateIconSidebar(e);
        //   },
        // },
        
      ],
    },
    {
      id: "Storage",
      label: "Storage Tools",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faBarsProgress}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      link: "/#",
      stateVariables: isStorage,
      click: function (e) {
        e.preventDefault();
        // setIsInfo(!isInfo);
        setIsStorage(!isStorage)
        setIscurrentState("Storage");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "images",
          label: "Files",
          link: "/property/files",
          icon: (
            // <FontAwesomeIcon
            //   icon={faImages}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={ImagesIcon} />
          ),
          stateVariables: isImage,
          parentId: "Storage",
          click: function (e) {
            e.preventDefault();
            setisImage(!isImage);
            updateIconSidebar(e);
          },
        },
        {
          id: "reports",
          label: "Reports",
          link: "/reports",
          icon: (
            <ReactSVG className="left-nav-icons"
             src={ImagesIcon} />
          ),
          stateVariables: isReport,
          parentId: "Storage",
          click: function (e) {
            e.preventDefault();
            setisReport(!isReport);
            updateIconSidebar(e);
          },
        },
      ]
    },
    
    // {
    //   id: "projectManager",
    //   label: "Project Manager",
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faListCheck}
    //       style={{ fontSize: "15px", color: "#ABB9E8" }}
    //     />
    //   ),
    //   link: "/#",
    //   stateVariables: isProjectManager,
    //   click: function (e) {
    //     e.preventDefault();
    //     setisProjectManager(!isProjectManager);
    //     setIscurrentState("projectManager");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "projects",
    //       label: "Project(s)",
    //       link: "/projects",
    //       isChildItem: true,
    //       stateVariables: isProjects,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faBarsProgress}
    //           style={{ fontSize: "15px", color: "#ABB9E8" }}
    //         />
    //       ),
    //       parentId: "projectManager",
    //       click: function (e) {
    //         e.preventDefault();
    //         setisProjects(!isProjects);
    //         updateIconSidebar(e);
    //       },
    //       childItems: [],
    //     },
    //   ],
    // },

    // {
    //   id: "serviceProvider",
    //   label: "Service Providers",
    //   icon: (
    //     <FontAwesomeIcon
    //       icon={faPeopleGroup}
    //       style={{ fontSize: "15px", color: "#ABB9E8" }}
    //     />
    //   ),
    //   link: "/#",
    //   stateVariables: isServiceProviders,
    //   click: function (e) {
    //     e.preventDefault();
    //     setisServiceProviders(!isServiceProviders);
    //     setIscurrentState("serviceProvider");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "contractors",
    //       label: "Contractors",
    //       link: "/contractors",
    //       isChildItem: true,
    //       stateVariables: isContractors,
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faHelmetSafety}
    //           style={{ fontSize: "15px", color: "#ABB9E8" }}
    //         />
    //       ),
    //       parentId: "serviceProvider",
    //       click: function (e) {
    //         e.preventDefault();
    //         setisContractors(!isContractors);
    //         updateIconSidebar(e);
    //       },
    //       childItems: [],
    //     },
    //     {
    //       id: "insuranceAgent",
    //       label: "Insurance Agents",
    //       link: "/insuranceAgent",
    //       parentId: "serviceProvider",
    //       icon: (
    //         <FontAwesomeIcon
    //           icon={faUserTie}
    //           style={{ fontSize: "15px", color: "#ABB9E8" }}
    //         />
    //       ),
    //     },
    //   ],
    // },
    ,
    {
      id: "propertyElements",
      label: "Property Elements",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faBookOpen}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      link: "/propertyElements",
      stateVariables: ispropertyElements,
      click: function (e) {
        e.preventDefault();
        setIspropertyElements(!ispropertyElements);
        setIscurrentState("Elements");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "settings",
          label: "Settings",
          link: "/propertyElements/settings/",
          stateVariables: ispropertyElementsSettings,
          icon: (
            <FontAwesomeIcon
              icon={faGear}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
            // <ReactSVG className="left-nav-icons"
            //  src={AppliancesIcon} />
          ),
          parentId: "Elements",
          click: function (e) {
            e.preventDefault();
            setispropertyElementsSettings(!ispropertyElementsSettings);
            updateIconSidebar(e);
          },
        },
        propertyElementsConfig["Appliance"] &&
        {
          id: "appliance",
          label: "Appliances",
          link: "/appliance",
          stateVariables: isappliance,
          icon: (
            // <FontAwesomeIcon
            //   icon={faPlug}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={AppliancesIcon} />
          ),
          parentId: "Elements",
          click: function (e) {
            e.preventDefault();
            setisappliance(!isappliance);
            updateIconSidebar(e);
          },
        },
        propertyElementsConfig["Finishes"] &&
        {
          id: "finishes",
          label: "Finishes",
          link: "/finishes",
          icon: (
            // <FontAwesomeIcon
            //   icon={faPalette}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={FinishesIcon} />
          ),
          parentId: "Elements",
        },
        propertyElementsConfig["Flooring"] &&
        {
          id: "flooring",
          label: "Flooring",
          link: "/flooring",
          icon: (
            // <FontAwesomeIcon
            //   icon={faSquare}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={FlooringIcon} />
          ),
          parentId: "Elements",
        },
        propertyElementsConfig["HVAC"] &&
        {
          id: "hvac",
          label: "HVAC",
          link: "/hvac",
          icon: (
            // <FontAwesomeIcon
            //   icon={faWind}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={HVACIcon} />
          ),
          parentId: "Elements",
        },
        propertyElementsConfig["Inventory"] &&
        {
          id: "inventory",
          label: "Inventory",
          link: "/inventory",
          icon: (
            // <FontAwesomeIcon
            //   icon={faBarcode}
            //   style={{ fontSize: "15px", color: "#ABB9E8" }}
            // />
            <ReactSVG className="left-nav-icons"
             src={InventoryIcon} />
          ),
          stateVariables: isinventory,
          parentId: "Elements",
          click: function (e) {
            e.preventDefault();
            setisinventory(!isinventory);
            updateIconSidebar(e);
          },
        },
        propertyElementsConfig["Insurance"] &&
        {
          id: "Insurance",
          label: "Insurance",
          link: "/Insurance",
          icon: (
            <ReactSVG className="left-nav-icons"
             src={InsuranceIcon} />
          ),
          stateVariables: isInsurance,
          parentId: "Elements",
          click: function (e) {
            e.preventDefault();
            setisInsurance(!isInsurance);
            updateIconSidebar(e);
          },
        },
        propertyElementsConfig["Utilities"] &&
        {
          id: "utilities",
          label: "Utilities",
          link: "/utilities",
          stateVariables: isutil,
          parentId: "Elements",
          icon: (
            <ReactSVG className="left-nav-icons"
            src={UtilitiesIcon} />
          ),
          click: function (e) {
            e.preventDefault();
            setisutil(!isutil);
            updateIconSidebar(e);
          },
        },
      ]
    },
    {
      id: "Management",
      label: "Management Tools",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faPeopleGroup}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      stateVariables: ismanagement,
      click: function (e) {
        e.preventDefault();
        setIsManagement(!ismanagement);
        setIscurrentState("Management");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "Maintenance",
          label: "Maintenance",
          link: "/maintenance",
          parentId: "Management",
          icon: (
            <ReactSVG className="left-nav-icons"
             src={MaintenanceIcon} />
          ),
        },
      ]
    },
    {
      id: "Support",
      label: "Support",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faHeadset}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      stateVariables: isSupport,
      click: function (e) {
        e.preventDefault();
        setIsSupport(!isSupport);
        setIscurrentState("Support");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "supportpage",
          label: "Support",
          link: "/support",
          parentId: "Support",
          icon: (
            <FontAwesomeIcon
              icon={faHeadset}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
          ),
        },
      ]
    }

  ];


  const staffMenuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "Support",
      label: "Support",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faHeadset}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      stateVariables: isSupport,
      click: function (e) {
        e.preventDefault();
        setIsSupport(!isSupport);
        setIscurrentState("Support");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "supportpage",
          label: "Support",
          link: "/support",
          parentId: "Support",
          icon: (
            <FontAwesomeIcon
              icon={faHeadset}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
          ),
        },
      ]
    }
  ]

  const contractorMenuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "Support",
      label: "Support",
      // icon: "las la-snowflake",
      icon: (
        <FontAwesomeIcon
          icon={faHeadset}
          style={{ fontSize: "15px", color: "#ABB9E8" }}
        />
      ),
      stateVariables: isSupport,
      click: function (e) {
        e.preventDefault();
        setIsSupport(!isSupport);
        setIscurrentState("Support");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "supportpage",
          label: "Support",
          link: "/support",
          parentId: "Support",
          icon: (
            <FontAwesomeIcon
              icon={faHeadset}
              style={{ fontSize: "15px", color: "#ABB9E8" }}
            />
          ),
        },
      ]
    }
  ]

  return <React.Fragment>{user.is_staff ?  staffMenuItems : user.role == "property owner"? menuItems : contractorMenuItems}</React.Fragment>;
};
export default Navdata;
