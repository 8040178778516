//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
// import { postFakeProfile, postJwtProfile } from "../../../helpers/fakebackend_helper";

// action
import { profileSuccess , updatePWDSuccess ,setBtnLoading , profileError, setLoading , updateSuccess, updateFailure ,resetProfileFlagChange } from "./reducer";

import { UpdateProfileMethod, changePwd, getContractorProfile, getProfile , getProfileMethod, updateProfile } from "../../../helpers/backend_helper";


export const getProfileThunk = (user) => async (dispatch) => {

    dispatch(setLoading())
    getProfileMethod(user)(user.id)
    .then((res)=> dispatch(profileSuccess(res)))
    .catch((err) => dispatch(profileError(err.autoMessage)))

}

export const editProfile = (user,data) => async (dispatch) => {
    dispatch(setBtnLoading())
    // updateProfile(uid,data)
    UpdateProfileMethod(user)(user.id,data)
    .then((res)=> dispatch(updateSuccess(res)))
    .catch((err)=> dispatch(updateFailure(err.message)))
};


export const changePWD = (data) => async (dispatch) => {
    dispatch(setBtnLoading())
    changePwd(data).then(
        (res)=> dispatch(updatePWDSuccess()) //
    )
    .catch((err)=> dispatch(updateFailure(err.message)))
}

// export const resetProfileFlag = () => {
//     try {
//         const response = resetProfileFlagChange();
//         return response;
//     } catch (error) {
//         return error;
//     }
// };