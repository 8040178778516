import {
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledDropdown,
  } from "reactstrap";
  
  // Formik
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import { useButtonFileUpload } from "../../Components/Hooks/buttonFileUpload";
  import { useState } from "react";
  import { SupportCaseChangeStatus, SupportCaseCreate, SupportCaseMsgCreate } from "../../helpers/backend_helper";
  import { toast } from "react-toastify";
  import { toastError } from "../../Components/error";
import { useSupportStatus } from "../../Components/Hooks/supportStatus";
  
  export const ChangeStatusModal = ({ isOpen, toggle , caseID ,onSuccess }) => {


    const [SupportStatusDropdown,getSupportStatusVal] = useSupportStatus()

    const [loading, setLoading] = useState(false);
    
    // const validation = useFormik({
    //   enableReinitialize: false,
    //   initialValues: {
    //     content: "",
    //   },
    //   validationSchema: Yup.object({
    //     content: Yup.string().required(
    //       "Please Enter Detailed Description Of Your Case"
    //     ),
    //   }),
    //   onSubmit: (values) => {
    //     var formdata = new FormData();
    //     formdata.append("content", values["content"]);
    //     var attachements = getAttachements();
    //     for (let file of attachements) {
    //       formdata.append("attachements", file.file);
    //     }
        
    //   },
    // });
  
    function onSubmit () {
        const val = getSupportStatusVal()
        if(!val) {
            toast.error("You need to provide the new status")
        }
        const data = {
            "status": val
        }
        setLoading(true);
        SupportCaseChangeStatus(caseID,data)
          .then((res) => {
            toast.success("Status Changed Successfully");
            onSuccess();
            toggle()
          })
          .catch((err) => toastError(err))
          .finally(() => setLoading(false));
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        size="lg"
        className="border-0 modal-dialog-scrollable"
        modalClassName="zoomIn"
      >
        <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
          Change Support Case Status
        </ModalHeader>
        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row className="g-3">
              <Col lg={12}>
                <div>
                  <Label htmlFor="client_nameName-field" className="form-label">
                    Select New Status
                  </Label>
                  {SupportStatusDropdown}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <button
                onClick={toggle}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Close
              </button>
              <button
                disabled={loading}
                type="submit"
                className="btn btn-success"
                id="add-btn"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Change Status
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    );
};
  