import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormFeedback,
  Button,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-router-dom";
import {
  AssetInputComponent,
  SortableAsset,
  SortableList,
} from "./AssetInputComponent";
import { generateId } from "../../../Components/utils/swipe_array";
import { useDispatch, useSelector } from "react-redux";
import {
  addAsset,
  reorderCancel,
  setFormState,
  setProject,
  set_delete_id,
  swipeAssets,
} from "../../../slices/contractor/createPortfolio/reducer";
import {
  API_PortfolioCreate,
  API_UpdatePortfolio,
} from "../../../helpers/backend_helper";
import {
  createProjectThunk,
  deleteAssetThunk,
  reorderAssetsThunk,
  updateProjectThunk,
} from "../../../slices/contractor/createPortfolio/thunk";
import { toastError } from "../../../Components/error";
import DeleteModal from "../../../Components/Common/DeleteModal";
// import FilesTableSelect from "./imagesTable";
// import { UploadImageComponent } from "../../../pages/property/PropertyFiles/upload/createImageModal";
// import { useDispatch } from "react-redux";

export const ProjectMainPage = ({
  breadCrumbTitle="Create New Project",
  breadCrumbpageTitle="Profile Projects"
}) => {
  const [verticalTab, setverticalTab] = useState("1");
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setverticalTab(tab);
    }
  };

  const { assets, btnloading, formState, project, selected_delete_id } =
    useSelector((state) => ({
      assets: state.portfolioCreate.assets,
      btnloading: state.portfolioCreate.btnloading,
      formState: state.portfolioCreate.state,
      project: state.portfolioCreate.project,
      selected_delete_id: state.portfolioCreate.selected_delete_id,
    }));


    // useEffect(() => {
    //   console.log(assets, btnloading, formState, project, selected_delete_id)
    // },[assets, btnloading, formState, project, selected_delete_id])


  const dispatch = useDispatch();

  // const [assets, setAssets] = useState([]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: project ? project.title : "",
      description: project ? project.description : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      description: Yup.string(),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const addNewFile = () => {
    dispatch(addAsset());
  };

  // const callback = () => {

  // }

  const createProject = () => {
    // console.log(validation.values)
    dispatch(createProjectThunk(validation.values));
  };

  const getAssetsReOrder = () => {
    return {
      items: assets
        .map((val, idx) => {
          if (val.created) {
            return { id: val.real_id, order: idx };
          }
        })
        .filter(Boolean),
    };
  };

  const onClickCreateBtn = () => {
    //checkpoint
    if (formState === "create") {
      dispatch(createProjectThunk(validation.values));
    } else if (formState === "update") {
      dispatch(setFormState("reorder"));
    } else {
      dispatch(reorderAssetsThunk(project.id, getAssetsReOrder()));
    }
  };

  const onClickCancelBtn = () => {
    //checkpoint
    if (formState === "create") {
      // create as draft
      var values = validation.values;
      if (!values["title"]) {
        values["title"] = "Drafted " + generateId();
        values["is_draft"] = true;
      }
      dispatch(createProjectThunk(values));
    } else if (formState === "update") {
      if (project.is_draft) {
        dispatch(updateProjectThunk(project, { is_draft: false }));
      } else {
        dispatch(updateProjectThunk(project, { is_draft: true }));
      }
    } else if (formState === "reorder") {
      // dispatch(setFormState("reorder"));
      dispatch(reorderCancel());
    }
  };

  const onClickDraftBtn = () => {
    //checkpoint
    if (formState === "create") {
      // create as draft
      var values = validation.values;
      if (!values["title"]) {
        values["title"] = "Drafted " + generateId();
      }
      values["is_draft"] = true;
      dispatch(createProjectThunk(values));
    } else {
      if (project.is_draft) {
        dispatch(updateProjectThunk(project, { is_draft: false }));
      } else {
        dispatch(updateProjectThunk(project, { is_draft: true }));
      }
    }
  };

  const nextButtonOnClick = () => {
    if (formState === "create") {
      toggleVertical("2");
    } else if (formState === "update" || formState === "reorder") {
      dispatch(updateProjectThunk(project, validation.values));
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={selected_delete_id}
        onDeleteClick={() => dispatch(deleteAssetThunk(selected_delete_id))}
        onCloseClick={() => dispatch(set_delete_id(null))}
        isLoading={btnloading}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={breadCrumbTitle} pageTitle={breadCrumbpageTitle} />
          <Row>
            <Col lg={3}>
              <Nav pills className="flex-column" id="v-pills-tab">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1");
                    }}
                    id="v-pills-home-tab"
                  >
                    Project Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2");
                    }}
                    id="v-pills-profile-tab"
                  >
                    Project Assets (images & videos)
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col lg={9}>
              <Card id="orderList">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">Add portfolio project</h5>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className="pt-0">
                  <TabContent
                    activeTab={verticalTab}
                    className=""
                    id="v-pills-tabContent"
                  >
                    <TabPane tabId="1" id="v-pills-home">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(e);
                        }}
                        className="needs-validation"
                        noValidate
                        action="index"
                      >
                        <Row>
                          <div className="mb-3">
                            <label className="form-label">
                              Project Title{" "}
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            <Input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Enter name"
                              name="title"
                              value={validation.values.title}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.title &&
                                validation.touched.title
                                  ? true
                                  : false
                              }
                            />

                            {validation.errors.title &&
                            validation.touched.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Project Description
                            </label>

                            <Input
                              type="textarea"
                              className="form-control"
                              id="description"
                              placeholder="Enter detailed description About your project"
                              rows={10}
                              name="description"
                              value={validation.values.description}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.description &&
                                validation.touched.description
                                  ? true
                                  : false
                              }
                            />

                            {validation.errors.description &&
                            validation.touched.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="hstack gap-2 justify-content-end">
                            <button
                              onClick={onClickDraftBtn}
                              type="button"
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                              disabled={btnloading}
                            >
                              {btnloading ? (
                                <Spinner size="sm" className="me-2">
                                  Loading...
                                </Spinner>
                              ) : null}
                              {formState === "create"
                                ? "Save As Draft"
                                : project.is_draft
                                ? "Puplish"
                                : "Draft"}
                            </button>
                            <button
                              disabled={btnloading}
                              // type="submit"
                              onClick={nextButtonOnClick}
                              className="btn btn-success"
                              // id="add-btn"
                            >
                              {btnloading ? (
                                <Spinner size="sm" className="me-2">
                                  Loading...
                                </Spinner>
                              ) : null}
                              {formState === "create"
                                ? "Next"
                                : formState === "update"
                                ? "Update"
                                : "Update"}
                            </button>
                          </div>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId="2" id="v-pills-profile">
                      <Row>
                        <SortableList
                          axis="xy"
                          items={assets}
                          shouldUseDragHandle={true}
                          useDragHandle
                          onSortEnd={({ oldIndex, newIndex }) => {
                            // setAssets(moveElement(assets, oldIndex, newIndex));
                            dispatch(swipeAssets({ oldIndex, newIndex }));
                          }}
                        />
                      </Row>
                      <>
                        <button
                          disabled={btnloading}
                          onClick={addNewFile}
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          {btnloading ? (
                            <Spinner size="sm" className="me-2">
                              Loading...
                            </Spinner>
                          ) : null}
                          Add new files
                        </button>
                        <span
                          style={{ marginLeft: "5px" }}
                          className="text-muted fs-14"
                        >
                          {assets.length} file(s) added
                        </span>
                      </>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          onClick={onClickCancelBtn}
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          disabled={btnloading}
                        >
                          {btnloading ? (
                            <Spinner size="sm" className="me-2">
                              Loading...
                            </Spinner>
                          ) : null}
                          {formState === "create"
                            ? "Save As Draft"
                            : formState === "reorder"
                            ? "cancel"
                            : formState === "update"
                            ? project.is_draft
                              ? "Puplish"
                              : "Draft"
                            : ""}
                        </button>

                        <button
                          disabled={btnloading}
                          // type="submit"
                          onClick={onClickCreateBtn}
                          className="btn btn-success"
                          id="add-btn"
                        >
                          {btnloading ? (
                            <Spinner size="sm" className="me-2">
                              Loading...
                            </Spinner>
                          ) : null}
                          {formState === "create"
                            ? "Create Project"
                            : formState === "update"
                            ? "Re-Order"
                            : "Save Changes"}
                        </button>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
