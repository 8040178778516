import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: "",
  success: "",
  loading: true,
  btnloading:false,
  user: null
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: { 
    profileSuccess(state, action) {
      // state.success = action.payload.status;
      console.log(action.payload)
      state.user = action.payload
      state.loading = false
    },
    setLoading(state,action) {
      state.loading = true
    },
    profileError(state, action) {
        // state.error = action.payload
        state.loading = false
    },
    editProfileChange(state){
      state = { ...state };
    },
    resetProfileFlagChange(state){
      state.success = null
    },
    setBtnLoading(state,action){
      state.btnloading = true
    },
    updateSuccess(state,action){
      state.btnloading = false
      state.user = action.payload
      state.success = "Your profile is updated successfully"
    },
    updatePWDSuccess(state,action){
      state.btnloading = false
      state.success = "Your password is changed successfully"
    },
    setError(state,action){
      state.error = action.payload
    },
    updateFailure(state,action){
      state.btnloading = false
      state.error = action.payload
    },
    resetMessages(state,action){
      state.error = ''
      state.success = ''
    }
    // btnloading
  },
});

export const {
    profileSuccess,
    profileError,
    editProfileChange,
    resetProfileFlagChange,
    setLoading,
    setBtnLoading,
    updateSuccess,
    updateFailure,
    resetMessages,
    setError,updatePWDSuccess
} = ProfileSlice.actions

export default ProfileSlice.reducer;