export function filter_object_list_keys(object,keys) {

    const selected = {};
    for (const key in object) {
        if (keys.includes(key)) {
            selected[key] = object[key];
        }
    }
    // console.log(selected);
    return selected
}