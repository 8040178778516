import React, { useEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
// import Route from './Routes';
import { getRouter, router } from "./Routes";
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

import { useDispatch, useSelector } from "react-redux";

import { getInfo } from "./slices/thunks";

import { Spinner as Loading } from "./pages/spinner/spinner";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";


import { registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

import {
  createBrowserRouter,
  createRoutesFromElements,
  // Route,
  RouterProvider,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSessionStorageKeepMeLogin } from "./Components/Hooks/keepMeLoginHook";
import Error500 from "./pages/AuthenticationInner/Errors/Error500";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

// register lottie and define custom element
defineElement(lottie.loadAnimation);

function App() {

  const isLoading = useSelector((state) => state.loading.isLoading);
  const user = useSelector((state) => state.Login.user);

  useSessionStorageKeepMeLogin();

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <RouterProvider router={getRouter(user)} />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
