import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Col,
  Spinner,
  Input,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import { Error } from "../../../../Components/error";
import { resetMessages } from "../../../../slices/property/finishes/reducer";

import { CreateForm } from "../upload/createForm";
import { useDispatch, useSelector } from "react-redux";

const UpdateNotesModal = ({
  edited,
  toggle,
  onSuccess = () => {},
}) => {

  const {
    error,
    success,
  } = useSelector((state) => ({
    error: state.notes.error,
    success: state.notes.success,
  }));

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(edited){
      dispatch(resetMessages())
    }
  }, [edited]);

  return (
    <Modal
      id="UpdatenotesModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      size="xl"
      className="modal-dialog-scrollable"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Notes Item Edit
      </ModalHeader>

      {success ? <Alert color="success">{success}</Alert> : null}

      {error ? <Error error={error} /> : null}

      <ModalBody>
        <CreateForm id={edited ? edited.id : -1} update onUpdateSucces={onSuccess} />
      </ModalBody>
    </Modal>
  );
};

export default UpdateNotesModal;
