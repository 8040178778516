import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useButtonFileUpload } from "../../Components/Hooks/buttonFileUpload";
import { useState } from "react";
import {
  SupportCaseChangeStatus,
  SupportCaseClose,
  SupportCaseCreate,
  SupportCaseMsgCreate,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { toastError } from "../../Components/error";
import { useSupportStatus } from "../../Components/Hooks/supportStatus";

export const CloseCaseModal = ({ isOpen, toggle, caseID, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);
    SupportCaseClose(caseID)
      .then((res) => {
        toast.success("Case Closed Successfully");
        toast.info("You can reopen case by adding new message");
        onSuccess();
        toggle();
      })
      .catch((err) => toastError(err))
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      className="border-0 modal-dialog-scrollable"
      modalClassName="zoomIn"
    >
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {/* <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#fa896b"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon> */}
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure you want to close this Support Case ?</h4>
            <p className="text-muted mx-4 mb-0">Note : You can reopen any closed Support Case by adding new message to it</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={toggle}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Yes, Close It!
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
